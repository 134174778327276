import { DcModel } from './../../model/dc.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import * as esriGeocoder from 'esri-leaflet-geocoder';
import 'leaflet-draw';
import 'leaflet-contextmenu';
import 'leaflet.markercluster';
import 'leaflet.measurecontrol';
import { LayerService } from '../../service/model/layer.service';
import { NGXLogger } from 'ngx-logger';
import { MapInfo, Icons, FILTER_OPTION_ALL } from '../../common/constants';
import { TrackingModel } from 'src/app/model/tracking.model';
import { LayerFilterModel } from '../../model/layer.filter.model';;
import { ObservedAreaService } from 'src/app/service/model/observed.area.service';
import { first } from 'rxjs/operators';
import { ObservedAreaModel } from 'src/app/model/observed.area.model';
import { ValveModel } from 'src/app/model/valve.model';
import { GeoModel } from 'src/app/model/geo.model';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { Permission } from 'src/app/model/enums.enum';
import { SourceType } from '../../model/enums.enum';
import { BandModel } from '../../model/band.model';
import { DuctModel } from '../../model/duct.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { SimfModel } from '../../model/simf.model';
import { KilometerMarkModel } from '../../model/kilometerMark.model';
import FieldUtils from 'src/app/service/util/field-utils';

// Obs: Nível máximo de Zoom é 20
const ZOOM_LEVEL_TO_SHOW_KILOMETER_MARKS = 12;
const CLUSTERING_ZOOM_LEVEL = 14;

@ProfileClassToConsole()
@Component({
  selector: 'app-base-map',
  templateUrl: './base-map.component.html',
  styleUrls: ['./base-map.component.scss']
})
export class BaseMapComponent implements OnInit, OnDestroy, AfterViewInit {
  public DC_HISTORY_ID = 'Histórico de DCs';
  public BAND_ID = 'Faixas';
  public GASDUCT_ID = 'Gasodutos';
  public OILDUCT_ID = 'Oleodutos';
  public SIMF_ID = 'SIMF';
  public DELIVERY_POINT_ID = 'Pontos de Entrega e Estações';
  public KILOMETER_MARK_ID = 'Marcos Quilométricos';
  public REFINARY_ID = 'Refinarias';
  public TERMINAL_ID = 'Terminais';
  public VALVE_ID = 'Válvulas';
  public OBSERVED_AREA_ID = 'Áreas Observadas';

  private lightBaseLayerName  = 'Leve';
  private streetBaseLayerName = 'Ruas';
  private hybridBaseLayerName = 'Híbrido';

  private CONTEXTMENU: any = {
    contextmenu: true, contextmenuItems: [{
      text: 'Criar Evento...',
      callback: (e) => { this.onCreateEvent(e)},
      index: 0
    },{
      text: 'Criar Verificação...',
      callback: (e) => { this.onCreateVerification(e)},
      index: 1
    }]
  }

  @Input() startWithStreet: boolean = false;

  @Input() lat: number = MapInfo.DEFAULT_LATITUDE;
  @Input() lng: number = MapInfo.DEFAULT_LONGITUDE;

  private _filterMap: LayerFilterModel;

  @Input()
  set filterMap(filter: LayerFilterModel){
    this._filterMap = filter;
    if (this.layersControl){
      this.updateAllFilters();
    }
  }

  get filterMap(): LayerFilterModel{
    return  this._filterMap;
  }

  // Propriedades do componente (usados no HTML)

  @Input() markerLayers = []; // leafletLayers - aqui adicionamos os marcadores individuais do tipo alertas, pontos e eventos, assim como marcadores de estado das operações
  @Input() mapStyle; // ngStyle do leaflet
  @Output() mapReady = new EventEmitter();
  @Output() mapEditUpdate = new EventEmitter();
  @Output() eventClicked = new EventEmitter();
  @Output() verificationClicked = new EventEmitter();
  @Output() layerReady = new EventEmitter();
  public options: any; // leafletOptions - usado somente na criação do mapa
  public layersControl: any; // leafletLayersControl

  // Internos

  private map: L.Map;
  private vehicleTrackingLayer: L.MarkerClusterGroup = this.createClusterGroup('assets/icons/vehicle.png');
  private userTrackingLayer: L.MarkerClusterGroup = this.createClusterGroup('assets/icons/professional.png');
  private allKmMarks: GeoModel[];
  public geoLayers: L.FeatureGroup[] = [];
  // Parametros de criação do geoLayer para cada um dos layers
  // Para que possam ser re-criados cada vez que a visualização é ligada, já que são destruídos quando é desligada
  // Marcos Quilométricos possuem tratamento diferenciado, pela quantidade de dados
  private geoLayerParam: {data: GeoModel[], isPoint: boolean, style: any, icon: string, filter_icon: string, getPopupContent: (feature: any) => string, filteredData: GeoModel[]}[] = [];

  layersMap: Map<string, L.LayerGroup>;

  addressResultsGroup: L.LayerGroup;

  trackingMarker = L.Marker.include({mobileObjectId: '', patrolTeamId: ''});

  drawLayer = new L.FeatureGroup();
  drawOptions: L.Control.DrawConstructorOptions;
  drawLocal;
  shownDraw: boolean;
  drawControl: L.Control.Draw;
  opacity: number = 1.0;

  constructor(private layerService: LayerService,
              private observedAreaService: ObservedAreaService,
              private logger: NGXLogger,
              protected authorizationService: AuthorizationService) {}

  ngOnDestroy() {
    this.logger.debug('BaseMapComponent.ngOnDestroy()');
    this.layersControl = null;
    this.geoLayers = null;
    this.map = null;
    this.geoLayerParam = null;
    this.options = null;
    this.markerLayers = [];
  }

  ngAfterViewInit(){
    this.logger.debug("BaseMapComponent.ngAfterViewInit()");
    this.generateGeoLayers();
  }

  ngOnInit() {
    this.logger.debug('BaseMapComponent.ngOnInit()');

    // Layers de fundo (o mapa em si)
    const baseLayers = this.generateBaseLayers();

    // Layers que aparecem no controle de camadas
    this.layersControl = {
      baseLayers // layers básicos do mapa: somente ruas ou com terreno
    };

    // Inicialização default para qual layer de fundo usar
    let startBaseLayerName = this.streetBaseLayerName;
    if (!this.startWithStreet) {
      startBaseLayerName = this.hybridBaseLayerName;
    }

    // Usado somente na criação do Mapa, mudanças posteriores são ignoradas
    this.options = {
      // measureControl: true,  -- se usar dessa forma, não consigo customizar o controle de medição
      zoom: MapInfo.DEFAULT_ZOOM,
      center: L.latLng(this.lat, this.lng),
      contextmenu: true,
      layers: [ baseLayers[startBaseLayerName] ]
    };

    this.drawOptions = {
      position: 'bottomright',
      draw: {
        marker: {
          icon: L.icon({
            iconUrl: Icons.ICON_URL_CRITICAL_POINT,
            iconSize: [ MapInfo.ROUTE_ICON_SIZE, MapInfo.ROUTE_ICON_SIZE ],
            iconAnchor: [ MapInfo.ROUTE_ICON_SIZE/2, MapInfo.ROUTE_ICON_SIZE/2 ]  // no centro do ícone
          }),
          repeatMode: true
        },
        polyline: {
          shapeOptions: {
            dashArray: undefined,
            color: MapInfo.DRAW_LINE_COLOR,
            opacity: 1.0,
            weight: MapInfo.DRAW_LINE_WEIGHT
          }
        },
        polygon: false,
        circle: false,
        circlemarker: false,
        rectangle: false
      },
      edit: {
        featureGroup: this.drawLayer,
        edit: {
          selectedPathOptions: {
            dashArray: undefined,
          }
        }
      }
    };

    this.drawLocal = {
      draw: {
        toolbar: {
          actions: {
            title: 'Cancelar edição',
            text: 'Cancelar'
          },
          finish: {
            title: 'Terminar edição',
            text: 'Terminar'
          },
          undo: {
            title: 'Remover último ponto',
            text: 'Remover último ponto'
          },
          buttons: {
            polyline: 'Adicionar rota',
            marker: 'Adicionar ponto',
          }
        },
        handlers: {
          marker: {
            tooltip: {
              start: 'Clique no mapa para posicionar o ponto.'
            }
          },
          polyline: {
            tooltip: {
              start: 'Clique para iniciar uma linha.',
              cont: 'Clique para continuar a linha.',
              end: 'Clique no último ponto para terminar a linha.'
            }
          }
        }
      },
      edit: {
        toolbar: {
          actions: {
            save: {
              title: 'Salvar modificações',
              text: 'Salvar'
            },
            cancel: {
              title: 'Cancelar edição, descarta todas as modificações',
              text: 'Cancelar'
            },
            clearAll: {
              title: 'Remove todos os pontos',
              text: 'Remover tudo'
            }
          },
          buttons: {
            edit: 'Editar',
            editDisabled: 'Nada para editar',
            remove: 'Remover',
            removeDisabled: 'Nada para remover'
          }
        },
        handlers: {
          edit: {
            tooltip: {
              text: 'Arraste os pontos para modifica-los.',
              subtext: 'Clique em Cancelar para desfazer modificações.'
            }
          },
          remove: {
            tooltip: {
              text: 'Clique em um elemento para remove-lo.'
            }
          }
        }
      }
    };
  }

  /***********************  BASE MAPS  *****************************/

  private generateBaseLayers(){
    /* Google */
    // lyrs
    // Hybrid: s,h;
    // Satellite: s;
    // Streets: m;
    // Terrain: p;
    const baseStreetGoogle = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });
    const baseHybridGoogle = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });

    /* ArcGIS
    var mapLink = '<a href="http://www.esri.com/">Esri</a>';
    var wholink = 'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
    L.tileLayer(
        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: '&copy; '+mapLink+', '+wholink,
        maxZoom: 18,
        }).addTo(map);
    */

    /* Mapbox */
    // https://docs.mapbox.com/api/maps/styles/
    const mapboxAccessToken = 'pk.eyJ1IjoibGJhcnJvcyIsImEiOiJjazkxYmozNHIwODV3M2hwNnZiM2s2OHJrIn0.pUhb9kWdU6mWUHLlxrdn5w';
    const mapboxAttribution = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';
    const mapboxUrlTemplate = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';

    const lightStreet = L.tileLayer(mapboxUrlTemplate, {
      attribution: mapboxAttribution,
      id: 'mapbox/light-v10',
      tileSize: 512,
      zoomOffset: -1,
      maxZoom: 20,
      accessToken: mapboxAccessToken
    });
    const baseStreet = L.tileLayer(mapboxUrlTemplate, {
      attribution: mapboxAttribution,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      maxZoom: 20,
      accessToken: mapboxAccessToken
    });
    const baseHybrid = L.tileLayer(mapboxUrlTemplate, {
      attribution: mapboxAttribution,
      id: 'mapbox/satellite-streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      maxZoom: 20,
      accessToken: mapboxAccessToken
    });

    const baseLayer = {};
    baseLayer[this.lightBaseLayerName]  = lightStreet;
    baseLayer[this.streetBaseLayerName] = baseStreet;
    baseLayer[this.streetBaseLayerName+'-Google'] = baseStreetGoogle;
    baseLayer[this.hybridBaseLayerName] = baseHybrid;
    baseLayer[this.hybridBaseLayerName+'-Google'] = baseHybridGoogle;
    return baseLayer;
  }

  /***********************  CAMADAS  *****************************/

  /**
   * Obtem as camadas do mapa e as adiciona ao componente de mapa
   */
  private generateGeoLayers(){
    this.logger.debug('BaseMapComponent - Generating GeoLayers...');

    // Áreas Observadas
    this.initializeObservedAreaLayer();

    // Faixas
    this.initializeBandLayer();

    // Pontos de entrega
    this.initializeDeliveryPointLayer();

    // Gasodutos
    this.initializeGasductLayer();

    // Oleodutos
    this.initializeOilductLayer();

    // Simf
    this.initializeSimfLayer();

    // Marcos Quilométricos
    this.initializeKmMarkersLayer();

    // Refinarias
    this.initializeRefinaryLayer();

    // Terminais
    this.initializeTerminalLayer();

    // Válvulas
    this.initializeValveLayer();

    // Histórico de DCs
    this.initializeDcsHistoryLayer();
  }

  public updateGeoLayer(dataType: string) {
    switch (dataType) {
      case ("Band"): {
        this.initializeBandLayer();
        break;
      };
      case ("GasDuct"): {
        this.initializeGasductLayer();
        break;
      };
      case ("OilDuct"): {
        this.initializeOilductLayer();
        break;
      };
      case ("Simf"): {
        this.initializeSimfLayer();
        break;
      };
      case ("KilometerMark"): {
        this.initializeKmMarkersLayer();
        break;
      };
      case ("DeliveryPoint"): {
        this.initializeDeliveryPointLayer();
        break;
      };
      case ("Terminal"): {
        this.initializeTerminalLayer();
        break;
      };
      case ("Refinary"): {
        this.initializeRefinaryLayer();
        break;
      };
      case ("Dc"): {
        this.initializeDcsHistoryLayer();
        break;
      };
      case ("Valve"): {
        this.initializeValveLayer();
        break;
      };
      case ("ObservedArea"): {
        this.initializeObservedAreaLayer();
        break;
      };
    }
  }

  public initializeObservedAreaLayer(){
     this.observedAreaService.loadListFromRestApi().pipe(first()).subscribe((observedAreas: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting Observed Areas...');
      this.fixLayerType(observedAreas, "ObservedArea");
      let showOnMap = false;
      if (this.geoLayers[this.OBSERVED_AREA_ID]){
        if (this.map.hasLayer(this.geoLayers[this.OBSERVED_AREA_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.OBSERVED_AREA_ID]);
      }
      this.initializeGeoLayer(this.OBSERVED_AREA_ID, observedAreas, false,
                              {
                                color: MapInfo.OBSERVED_AREA_LINE_COLOR,
                                weight: MapInfo.OBSERVED_AREA_LINE_WEIGHT,
                                dashArray: MapInfo.OBSERVED_AREA_LINE_STYLE
                              },
                              'assets/icons/layers/obs_area.png', 'assets/icons/filters/obs_area.png', ObservedAreaModel.getPopupContent);

      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.OBSERVED_AREA_ID]);
      }

    },(error) => {
      this.logger.error('Erro ao obter o áreas observadas do mapa: ', error);
    });
  }

  // Para compatibilidade com dados antigos
  public fixLayerType(geoLayers: GeoModel[], type: string){
    geoLayers.forEach(layer => {
      layer.type = type;
    });
  }

  fixDcName(geoLayers: GeoModel[]){
    geoLayers.forEach(layer => {
      if (!layer.name) DcModel.updateName(layer as DcModel);
    });
  }

  public initializeBandLayer() {
    this.layerService.getBands().pipe(first()).subscribe((bands: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting bands...');
      this.fixLayerType(bands, "Band");
      if (this.geoLayers[this.BAND_ID]){
        this.removeLayerFromMap(this.geoLayers[this.BAND_ID]);
      }
      this.initializeGeoLayer(this.BAND_ID, bands, false,
                               { color: MapInfo.BAND_COLOR, weight: MapInfo.DUCT_WEIGHT }, null, 'assets/icons/filters/duct.png', BandModel.getPopupContent);
      this.addLayerToMap(this.geoLayers[this.BAND_ID]); // Adiciona Faixas por padrão ao inicializar
    },
    (error) => {
      this.logger.error('Erro ao obter as faixas do mapa. ', error);
    });
  }

  public initializeDeliveryPointLayer() {
    this.layerService.getDeliveryPoints().pipe(first()).subscribe((deliveryPoints: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting delivery points...');
      this.fixLayerType(deliveryPoints, "DeliveryPoint");
      let showOnMap = false;
      if (this.geoLayers[this.DELIVERY_POINT_ID]){
        if (this.map.hasLayer(this.geoLayers[this.DELIVERY_POINT_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.DELIVERY_POINT_ID]);
      }
      this.initializeGeoLayer(this.DELIVERY_POINT_ID, deliveryPoints, true,
                               null, 'assets/icons/layers/delivery-point.png', 'assets/icons/filters/delivery-point.png', GeoModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.DELIVERY_POINT_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter os pontos de entrega do mapa ', error);
    });
  }

  public initializeGasductLayer() {
    this.layerService.getGasDucts().pipe(first()).subscribe((gasDucts: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting gasducts...');
      this.fixLayerType(gasDucts, "GasDuct");
      let showOnMap = false;
      if (this.geoLayers[this.GASDUCT_ID]){
        if (this.map.hasLayer(this.geoLayers[this.GASDUCT_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.GASDUCT_ID]);
      }
      this.initializeGeoLayer(this.GASDUCT_ID, gasDucts, false,
                               { color: MapInfo.GASDUCT_COLOR, weight: MapInfo.DUCT_WEIGHT }, null, 'assets/icons/filters/duct.png', DuctModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.GASDUCT_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter os gasodutos do mapa ', error);
    });
  }

  public initializeOilductLayer() {
    this.layerService.getOilDucts().pipe(first()).subscribe((oilDucts: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting oilducts...');
      this.fixLayerType(oilDucts, "OilDuct");
      let showOnMap = false;
      if (this.geoLayers[this.OILDUCT_ID]){
        if (this.map.hasLayer(this.geoLayers[this.OILDUCT_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.OILDUCT_ID]);
      }
      this.initializeGeoLayer(this.OILDUCT_ID, oilDucts, false,
                               { color: MapInfo.OILDUCT_COLOR, weight: MapInfo.DUCT_WEIGHT }, null, 'assets/icons/filters/duct.png', DuctModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.OILDUCT_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter os oleodutos do mapa ', error);
    });
  }

  public initializeSimfLayer() {
    this.layerService.getSimf().pipe(first()).subscribe((simfs: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting SIMFs...');
      this.fixLayerType(simfs, "Simf");
      let showOnMap = false;
      if (this.geoLayers[this.SIMF_ID]){
        if (this.map.hasLayer(this.geoLayers[this.SIMF_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.SIMF_ID]);
      }
      this.initializeGeoLayer(this.SIMF_ID, simfs, false,
                               { color: MapInfo.SIMF_COLOR, weight: MapInfo.DUCT_WEIGHT }, null, 'assets/icons/filters/duct.png', SimfModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.SIMF_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter os simfs do mapa ', error);
    });
  }

  public initializeKmMarkersLayer() {
    this.layerService.getKilometerMarks().pipe(first()).subscribe((kilometerMarks: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting Km markers...');
      this.fixLayerType(kilometerMarks, "KilometerMark");
      this.allKmMarks = kilometerMarks;
      kilometerMarks = [];
      let showOnMap = false;
      if (this.geoLayers[this.KILOMETER_MARK_ID]){
        if (this.map.hasLayer(this.geoLayers[this.KILOMETER_MARK_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.KILOMETER_MARK_ID]);
      }
      this.initializeGeoLayer(this.KILOMETER_MARK_ID , kilometerMarks, true,
                               null, 'assets/icons/layers/km-marker.png', 'assets/icons/filters/km-marker.png', KilometerMarkModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.KILOMETER_MARK_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter os marcadores de quilometragem do mapa ', error);
    });
  }

  public initializeRefinaryLayer() {
    this.layerService.getRefinary().pipe(first()).subscribe((refinaries: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting refinaries...');
      this.fixLayerType(refinaries, "Refinary");
      let showOnMap = false;
      if (this.geoLayers[this.REFINARY_ID]){
        if (this.map.hasLayer(this.geoLayers[this.REFINARY_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.REFINARY_ID]);
      }
      this.initializeGeoLayer(this.REFINARY_ID, refinaries, true,
                               null, 'assets/icons/layers/refinery.png', 'assets/icons/filters/refinery.png', GeoModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.REFINARY_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter as refinarias do mapa ', error);
    });
  }

  public initializeTerminalLayer() {
    this.layerService.getTerminal().pipe(first()).subscribe((terminals: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting terminals...');
      this.fixLayerType(terminals, "Terminal");
      let showOnMap = false;
      if (this.geoLayers[this.TERMINAL_ID]){
        if (this.map.hasLayer(this.geoLayers[this.TERMINAL_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.TERMINAL_ID]);
      }
      this.initializeGeoLayer(this.TERMINAL_ID, terminals, true,
                               null, 'assets/icons/layers/terminal.png', 'assets/icons/filters/terminal.png', GeoModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.TERMINAL_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter os terminais do mapa ', error);
    });
  }

  public initializeValveLayer() {
    this.layerService.getValve().pipe(first()).subscribe((valves: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting valves...');
      this.fixLayerType(valves, "Valve");
      let showOnMap = false;
      if (this.geoLayers[this.VALVE_ID]){
        if (this.map.hasLayer(this.geoLayers[this.VALVE_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.VALVE_ID]);
      }
      this.initializeGeoLayer(this.VALVE_ID, valves, true,
                               null, 'assets/icons/layers/valve.png', 'assets/icons/filters/valve.png', ValveModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.VALVE_ID]);
      }
    },
    (error) => {
      this.logger.error('Erro ao obter as válvulas do mapa ', error);
    });
  }

  public initializeDcsHistoryLayer() {
    this.layerService.getDCsHistory().pipe(first()).subscribe((dcs: GeoModel[]) => {
      if (!this.geoLayers) return; // Terminou de carregar depois que o mapa foi fechado
      this.logger.debug('BaseMapComponent - Getting DCs...');
      this.fixLayerType(dcs, "Dc");
      this.fixDcName(dcs);
      let showOnMap = false;
      if (this.geoLayers[this.DC_HISTORY_ID]){
        if (this.map.hasLayer(this.geoLayers[this.DC_HISTORY_ID])) showOnMap = true;
        this.removeLayerFromMap(this.geoLayers[this.DC_HISTORY_ID]);
      }
      this.initializeGeoLayer(this.DC_HISTORY_ID, dcs, true,
                               null, 'assets/icons/layers/dc.png', 'assets/icons/filters/dc.png', DcModel.getPopupContent);
      if (showOnMap){
        this.addLayerToMap(this.geoLayers[this.DC_HISTORY_ID]);
      }
     },
    (error) => {
      this.logger.error('Erro ao obter o histórico de DCs do mapa ', error);
    });
  }

  public layerFilterIcon(id: string): string {
    return this.geoLayerParam[id].filter_icon;
  }

  public layerData(id: string): GeoModel[] {
    if (id == this.KILOMETER_MARK_ID) {
      return this.allKmMarks;
    }
    else {
      if (!this.geoLayerParam[id]) return [];

      if (!this.geoLayerParam[id].filteredData) {
        return this.geoLayerParam[id].data;
      }
      return this.geoLayerParam[id].filteredData;
    }
  }

  public layerPopupContent(id: string) {
    if (!this.geoLayerParam[id]) return null;
    return this.geoLayerParam[id].getPopupContent;
  }

  /** Transforma um dado geográfico em uma feature do mapa */
  private transformData(layerData: GeoModel[]){
    return layerData.map((item) => {
      const obj = item.geometry;

      if(!obj) return;

      obj.properties = {};
      for (const key in item){
        if (key === 'geometry'){
          continue;
        }
        obj.properties[key] = item[key];
      }
      return obj;
    });
  }

  public isLayerOptionToProfile(id):boolean{
    /**Só estão sendo tratadas as camadas Dcs e Areas Observadas */
    if (id != this.DC_HISTORY_ID && id != this.OBSERVED_AREA_ID)  return true;
    /** Valida se o perfil pode ver as camadas de Dcs e Areas Observadas */
    if (this.authorizationService.userHasPermission(Permission.VIEW_MAP_DC_HISTORY_ID) && id == this.DC_HISTORY_ID)  return true;
    if (this.authorizationService.userHasPermission(Permission.VIEW_MAP_OBSERVED_AREA_ID) && id == this.OBSERVED_AREA_ID)  return true ; 
    return false 
  }

  private layerFilterSort(a: GeoModel, b: GeoModel) {
    return a.name.localeCompare(b.name);
  }

  /** Inicializa a camada de dados geográficos */
  private initializeGeoLayer(id: string, data: GeoModel[], isPoint: boolean, style: any, icon: string, filter_icon: string, getPopupContent: (feature: any) => string){
    this.geoLayerParam[id] = {data, isPoint, style, icon, filter_icon, getPopupContent};
    if (this.isLayerOptionToProfile(id)){
      this.geoLayerParam[id].filteredData = (data as GeoModel []).filter(g => this.filterGeoModel(id, g));
      this.geoLayerParam[id].filteredData.sort(this.layerFilterSort);
      this.geoLayers[id] = this.createGeoLayer(id, this.geoLayerParam[id].filteredData, isPoint, style, icon, getPopupContent);
    }
    this.layerReady.emit(id);
  }

  /** Inicializa a camada de objetos móveis e controle de camadas, chamado uma única vez */
  public initializeLayers(layersMap: Map<string, L.LayerGroup>) {
    this.addLayerToMap(this.vehicleTrackingLayer);
    this.addLayerToMap(this.userTrackingLayer);
    this.layersMap = layersMap;
  }

  private createClusterGroup(icon){
    return L.markerClusterGroup({
      showCoverageOnHover: false,
      disableClusteringAtZoom: CLUSTERING_ZOOM_LEVEL,
      iconCreateFunction: function(cluster) {
        var childCount = cluster.getChildCount();
        return new L.DivIcon({ html: '<div><span>' + childCount + '</span><img src="' + icon + '"/></div>', className: 'marker-cluster', iconSize: new L.Point(32, 32) });
      }
    });
  }

  private createGeoLayer(id: string, data: GeoModel[], isPoint: boolean, style: any, icon: any,
                         getPopupContent: (feature: any) => string): L.FeatureGroup {
    
    const geoData = this.transformData(data);

    let geoLayer: L.FeatureGroup;

    const options = {};
    if (icon){
      options['icon'] = L.icon({
        iconSize: [ MapInfo.LAYERS_ICON_SIZE, MapInfo.LAYERS_ICON_SIZE ],
        iconAnchor: [ MapInfo.LAYERS_ICON_SIZE/2, MapInfo.LAYERS_ICON_SIZE/2 ],  // no centro do ícone
        iconUrl: icon
      });
    }

    if (isPoint){
      geoLayer = this.createClusterGroup(icon);

      L.geoJSON(geoData as any, {
        pointToLayer: (feature, latLng: L.LatLng) => {
          const marker = L.marker(latLng, options);
          if (getPopupContent) {
            feature.properties.lat = latLng.lat;
            feature.properties.lng = latLng.lng;
            marker.bindPopup(getPopupContent(feature), {maxWidth: 500});
          }

          if (id == this.KILOMETER_MARK_ID) {
            marker.bindTooltip(KilometerMarkModel.getTooltipContent(feature));
          }

          geoLayer.addLayer(marker);
          return null;
        },
      });
    }
    else{
      var polygonsWithCenters = L.layerGroup();

      geoLayer = L.geoJSON(geoData as any, {
        style: () => {
          return style;
        },
        onEachFeature: (feature, layer) => {
          if (getPopupContent) {
            layer.bindPopup(getPopupContent(feature));
          }

          if(id == this.OBSERVED_AREA_ID){ // Poligonos de áreas observadas possuem um marcador no centro
            let areaCenter = L.geoJSON(feature).getBounds().getCenter();
            let centerMarker  = L.marker(areaCenter, options).bindPopup(getPopupContent(feature));
            centerMarker.addTo(polygonsWithCenters);
          }
        },
      });
    }

    if(id == this.OBSERVED_AREA_ID){
      polygonsWithCenters.addTo(geoLayer);
    }

    this.layersMap.set(id, geoLayer);

    this.setGeoLayerOpacity(id, geoLayer, this.opacity);

    return geoLayer;
  }

  private updateLayerOnMap(id: string, addToMap?: boolean){
    const layer = this.geoLayers[id];

    // Se a camada estava visível, remove e cria novamente
    // Porque no processo de criação da camada são aplicados os filtros

    if (this.map.hasLayer(layer)){
      this.map.removeLayer(layer);
      addToMap = true; // Estava visivel
    }
    const cacheItem = this.geoLayerParam[id];
    if(!cacheItem) return;

    cacheItem.filteredData = (cacheItem.data as GeoModel []).filter(g => this.filterGeoModel(id, g));
    cacheItem.filteredData.sort(this.layerFilterSort);

    this.geoLayers[id] = this.createGeoLayer(id, cacheItem.filteredData, cacheItem.isPoint,
                                             cacheItem.style, cacheItem.icon, cacheItem.getPopupContent);

    if (addToMap){ // Torna visivel novamente se estava antes
      this.addLayerToMap(this.geoLayers[id]);
    }
  }

  /***********************  TRACKING  *****************************/

  /** Atualiza os dados do objeto móvel no mapa */
  public updateTrackingMarker(tracking: TrackingModel, fit: boolean){
    let marker: L.Marker = this.searchTrackingMarker(tracking);
    if (marker) {
      marker.setIcon(tracking.icon);

      const newLatLng = new L.LatLng(tracking.signal.latitude, tracking.signal.longitude);
      marker.setLatLng(newLatLng);

      marker.setPopupContent(TrackingModel.getPopupContent(tracking));
      marker.setTooltipContent(TrackingModel.getTooltipContent(tracking));
    }
    else {
      marker = this.createTrackingMarker(tracking);

      if (tracking.signal.sourceType == SourceType.MOBILE_APP){
        this.userTrackingLayer.addLayer(marker);
      }
      else{
        this.vehicleTrackingLayer.addLayer(marker);
      }
    }

    if (fit){
      this.fitTrackingMarker(marker);
      marker.openPopup();
      return marker;
    }
  }

  public centerTrackingMarker(tracking: TrackingModel){
    let marker: L.Marker = this.searchTrackingMarker(tracking);
    if (marker) {
      this.fitTrackingMarker(marker);
    }
    return marker;
  }

  public centerTrackingMarkers(trackings: TrackingModel[]){
    let markers: L.Marker[] = [];
    trackings.forEach(tracking => {
      let marker: L.Marker = this.searchTrackingMarker(tracking);
      if (marker) {
        markers.push(marker);
      }
    });
    this.fitTrackingMarkers(markers);
    return markers;
  }

  /** Remove o objeto móvel do mapa */
  public removeTrackingMarker(tracking: TrackingModel){
    if(!tracking) return;

    const marker: L.Marker = this.searchTrackingMarker(tracking);
    if (marker) {
      if (this.userTrackingLayer.hasLayer(marker)){
        this.userTrackingLayer.removeLayer(marker);
      }
      if (this.vehicleTrackingLayer.hasLayer(marker)){
        this.vehicleTrackingLayer.removeLayer(marker);
      }
    }
  }

  /** Busca o objeto móvel no grupo */
  private searchTrackingMarker(tracking: TrackingModel): L.Marker {
    let layer: L.Marker;
    this.vehicleTrackingLayer.eachLayer( (marker: any) => {
      if(this.isTrackingMarker(marker, tracking)) {
         layer = marker;
      }
    });
    this.userTrackingLayer.eachLayer( (marker: any) => {
      if(this.isTrackingMarker(marker, tracking)) {
         layer = marker;
      }
    });
    return layer;
  }

  public removeTrackingMarkers() {
    this.userTrackingLayer.clearLayers();
    this.vehicleTrackingLayer.clearLayers();
  }

  private isTrackingMarker(marker, tracking: TrackingModel) { // Use "any" because of mobileObjectId
    return marker.mobileObjectId == tracking?.signal?.mobileObjectId;
  }

  private createTrackingMarker(tracking: TrackingModel) {
    // se o tracking tem equipe cria o marker com context menu
    const marker = tracking?.patrolTeam ? new this.trackingMarker([tracking.signal.latitude, tracking.signal.longitude], this.CONTEXTMENU  ).bindPopup('') : new this.trackingMarker([tracking.signal.latitude, tracking.signal.longitude]).bindPopup('');
    marker.mobileObjectId = tracking.signal.mobileObjectId;
    marker.patrolTeamId = tracking.signal.teamId;

    marker.setIcon(tracking.icon);

    marker.bindPopup(TrackingModel.getPopupContent(tracking), { minWidth: MapInfo.POPUP_MIN_WIDTH, autoPan: false });
    marker.bindTooltip(TrackingModel.getTooltipContent(tracking));

    marker.setOpacity(this.opacity);

    return marker;
  }

  /***********************  INTERACTION  *****************************/

  public invalidateSize() {
    this.map.invalidateSize();
  }

  private getAddressMarkerPopupContent(latitude: number, longitude: number, address: string){
    return `<h5 style="text-align: center">Marcador de Endereço</h5>
            <div>Lat, Long:${FieldUtils.coordToString(latitude)},${FieldUtils.coordToString(longitude)}</div>
            <p>${address}</p>`;
  }

  private getAddressMarkerTooltipContent(latitude: number, longitude: number, address: string){
    return `<div>Lat, Long:${FieldUtils.coordToString(latitude)},${FieldUtils.coordToString(longitude)}</div>
            <div>${address}</div>`;
  }

  public searchLatLngIcon = L.icon({
    iconUrl: Icons.ICON_URL_MARKER,
    iconSize: [ MapInfo.MARKER_ICON_SIZE, MapInfo.MARKER_ICON_SIZE ],
    iconAnchor: [ MapInfo.MARKER_ICON_SIZE/2, MapInfo.MARKER_ICON_SIZE ]  // no centro da base do ícone
  });

  public clearSearchResults() {
    this.addressResultsGroup.clearLayers();  
  }

  private addSearchAddressControl(){

    // Workaround porque os import não funcionaram
    const geosearch = (esriGeocoder as any).geosearch;
    const arcgisOnlineProvider = (esriGeocoder as any).arcgisOnlineProvider;

    const searchControl = geosearch({
      position: "topright",
      placeholder: "Endereço",
      title: 'Busca de Endereço',
      allowMultipleResults: false,
      useMapBounds: false,
      providers: [
        arcgisOnlineProvider({
          token: MapInfo.ARCGIS_TOKEN,
          countries: "BR",
          maxResults: 10
        })
      ]
    }).addTo(this.map);

    this.addressResultsGroup = L.layerGroup().addTo(this.map);

    searchControl.on("results", (data: any) => {
      this.addressResultsGroup.clearLayers();

      for (let i = data.results.length - 1; i >= 0; i--) {
        const marker = L.marker(data.results[i].latlng);
        marker.setIcon(this.searchLatLngIcon);
        const latLong = data.results[i].latlng;
        marker.bindPopup(this.getAddressMarkerPopupContent(latLong.lat, latLong.lng, data.results[i].properties.LongLabel), { minWidth: MapInfo.POPUP_MIN_WIDTH });
        marker.bindTooltip(this.getAddressMarkerTooltipContent(latLong.lat, latLong.lng, data.results[i].properties.LongLabel));
        this.addressResultsGroup.addLayer(marker);
        marker.openPopup();
      }
    });
  }

  private addMeasureControl() {
    // Customização do controle de medição. Não consegui fazer isso pelo options dele.
    let c = L.Control as any;
    c.MeasureControl.TITLE = "Medir Distância";
    let d = L.drawLocal as any;
    d.draw.handlers.polyline.tooltip.start = "Clique para iniciar uma linha"
    d.draw.handlers.polyline.tooltip.end = "Clique no último ponto para terminar a linha"
    d.draw.handlers.polyline.tooltip.cont = "Clique para continuar a linha"
    let m = c.measureControl();
    m.addTo(this.map);
  }

  // Chamada quando o mapa do leaflet já foi disponibilizado pelo ngx-leaflet
  // Algumas coisas do Mapa podem ser manipulados, mas nem tudo
  // Identificamos que a fitBounds nem sempre funciona
  // Identificamos também que essa chamada acontece antes da ngAfterViewInit
  onMapReady(map: L.Map) {
    this.logger.debug("BaseMapComponent.onMapReady()");
    this.map = map;

    this.addMeasureControl();

    this.addSearchAddressControl();

    this.mapReady.emit(this.map);
  }

  onMapZoomEnd(event: L.LeafletEvent) {
    this.logger.debug("BaseMapComponent.onMapZoomEnd() - Zoom: ", this.map.getZoom());
    if (!this.checkKmMarksZoomLevel()) {
      this.updateFilter(this.KILOMETER_MARK_ID);
    }
  }

  onMapMoveEnd(event: L.LeafletEvent) {
  }

  onDrawReady(drawControl) {
    this.drawControl = drawControl; // Não utilizado, para uso futuro se for o caso. Senão, remover.
  }

  onDrawCreated(e: L.DrawEvents.Created) {
    this.drawLayer.addLayer(e.layer);
    const idlayer = this.drawLayer.getLayerId(e.layer);
    this.mapEditUpdate.emit({ createdLayer: e.layer, layerType: e.layerType, idlayer});
  }

  onDrawEdited(e: L.DrawEvents.Edited) {
    this.mapEditUpdate.emit({ editedLayers: e.layers });
  }

  onDrawDeleted(e: L.DrawEvents.Deleted) {
    this.mapEditUpdate.emit({ deletedLayers: e.layers });
  }

  onDrawEditStart(e: L.DrawEvents.EditStart){
    this.mapEditUpdate.emit({ editStart: true });
  } 

  /***********************  FILTRO DE CAMADAS  *****************************/

  public filterGeoModel(id, geoModel): boolean{
    if (!geoModel) return false;
    const hasStates = this.checkFilterByStates(id, geoModel)
    const hasBand = this.checkFilterByBand(id, geoModel);
    const hasStatus = this.checkObservedAreaFilterByStatus(id, geoModel);
    const hasResponsible = this.checkObservedAreaFilterByResponsible(id, geoModel);
    const isValidObservedArea = this.checkObservedAreaFilterByAreaId(id, geoModel);
    const hasYear = this.checkDcHistoryFilterByYear(id, geoModel);
    return hasStates && hasBand && hasResponsible && isValidObservedArea && hasStatus && hasYear;
  }

  private checkFilterByStates(id: string, geoModel) : boolean{
    const myStates: [] = geoModel.states;

    let hasStates = true;

    if (this.filterMap && this.filterMap.states && this.filterMap.states.length > 0) {
      if(!myStates || myStates.length == 0) return false;

      for(let state of myStates){
        hasStates = this.filterMap.states.includes(state);
        if(hasStates) break;
      };
    }
    return hasStates;
  }

  private checkFilterByBand(id: string, geoModel): boolean{
    let isBandValid = true;
    
    if (id === this.BAND_ID){ // Se é do tipo faixa, usa o próprio ID
      if (this.filterMap && this.filterMap.bandIds && this.filterMap.bandIds.length > 0) {
        let myBandId = geoModel.id;
        isBandValid = this.filterMap.bandIds.includes(myBandId);
      }
    }
    else {
      if (this.filterMap && this.filterMap.bandIds && this.filterMap.bandIds.length > 0) {
        let myBandId = geoModel.bandId;
        if (myBandId) {
          isBandValid = this.filterMap.bandIds.includes(myBandId);
        }
        else {
          let myBandName = geoModel.band;
          isBandValid = this.filterMap.bandNames.includes(myBandName);
        }
      }
    }

    return isBandValid;
  }

   private checkObservedAreaFilterByStatus(id: string, geoModel){
    let hasStatus = true;

    if (id === this.OBSERVED_AREA_ID){
      if (this.filterMap && this.filterMap.areaStatus !== FILTER_OPTION_ALL) {
        const myStatus = geoModel.status;
        hasStatus = (this.filterMap.areaStatus === myStatus);
      }
    }

    return hasStatus;
  }

   private checkObservedAreaFilterByResponsible(id: string, geoModel){
    let hasResponsible = true;

    if (id === this.OBSERVED_AREA_ID){
      if (this.filterMap && this.filterMap.responsiblesIds?.length > 0) {
        const myResponsible = geoModel.responsible.id;
        hasResponsible = this.filterMap.responsiblesIds.includes(myResponsible);
      }
    }

    return hasResponsible;
  }

   private checkObservedAreaFilterByAreaId(id: string, geoModel){
    let isValidArea = true;

    if (id === this.OBSERVED_AREA_ID){
      if (this.filterMap && this.filterMap.areasIds?.length > 0) {
        const myAreaId = geoModel.id;
        isValidArea = this.filterMap.areasIds.includes(myAreaId);
      }
    }

    return isValidArea;
  }

  private checkDcHistoryFilterByYear(id: string, geoModel){
    let hasYear = true;
    if (id === this.DC_HISTORY_ID){
      if (this.filterMap && this.filterMap.years?.length > 0) {
        const myYear = geoModel.year;
        hasYear = this.filterMap.years.includes(myYear);
      }
    }

    return hasYear;
  }

  updateFilter(id: string, fit?: boolean, addToMap?: boolean){
    if(id === this.KILOMETER_MARK_ID){
      this.filterKmMarksByZoom();
    }

    this.updateLayerOnMap(id, addToMap);

    if (fit) {
      this.fitFilteredLayer(this.geoLayers[id]);
    }
  }

  updateAllFilters() {
    for (const id in this.geoLayers) {
      this.updateFilter(id);
    }
  }

  updateFilterBands() {
    this.updateFilter(this.BAND_ID);

    // Faixas afetam Marcos Quilométricos, Simf, Pontos de Entrega,  Estações, Válvulas e Áreas Observadas
    this.updateFilter(this.DELIVERY_POINT_ID);
    this.updateFilter(this.KILOMETER_MARK_ID);
    this.updateFilter(this.OBSERVED_AREA_ID);
    this.updateFilter(this.VALVE_ID);
    this.updateFilter(this.SIMF_ID);
  }

  private checkKmMarksZoomLevel(){
    const cacheItem = this.geoLayerParam[this.KILOMETER_MARK_ID];
    if (cacheItem){
      if (this.map.getZoom() >= ZOOM_LEVEL_TO_SHOW_KILOMETER_MARKS){
        return cacheItem.showAll == true;
      }
      else {
        return !cacheItem.showAll;
      }
    }
    return true;
  }

  private filterKmMarksByZoom(){
    // Zoom só afeta KmMarks, então seu filtro é feito a parte
    const cacheItem = this.geoLayerParam[this.KILOMETER_MARK_ID];
    if (cacheItem){
      if (this.map.getZoom() >= ZOOM_LEVEL_TO_SHOW_KILOMETER_MARKS){
        cacheItem.data = this.allKmMarks;
        cacheItem.showAll = true;
      }
      else {
        cacheItem.data = [];
        cacheItem.showAll = false;
      }
    }
  }

  /***********************  FIT  *****************************/

  private fitTrackingMarker(marker: L.Marker){
    this.fitMarker(marker);
  }

  private fitTrackingMarkers(markers: L.Marker[]){
    var group = new L.FeatureGroup();
    markers.forEach(marker => {
      group.addLayer(marker);
    });
    this.fitBounds(group.getBounds());
  }

  fitMarker(marker: L.Marker) {
    this.fitPoint(marker.getLatLng());
  }

  private fitFilteredLayer(layer: L.FeatureGroup) {
    if(!layer) return;

    let bounds = layer.getBounds();
    if(!this.map){ // Precisa esperar um pouco quando Map Page não está pronta ainda
      setTimeout(() => {
        this.fitBounds(bounds);
      }, 5000);
    }else{
      this.fitBounds(bounds);
    }
  }

  fitPoint(latLong: L.LatLngExpression) {
    this.map.setView(latLong, MapInfo.FIT_ZOOM);
  }

  fitBounds(geoBounds: L.LatLngBounds){
    if (this.map && geoBounds && geoBounds.isValid()){
      this.map.fitBounds(geoBounds, {animate: true});
    }
  }

  fitZoom() {
    this.map.setView({lat: MapInfo.DEFAULT_LATITUDE, lng: MapInfo.DEFAULT_LONGITUDE}, MapInfo.DEFAULT_ZOOM);
  }

  /***********************  MARKERS  *****************************/

  createMarker(coordinates: L.LatLngExpression, icon: L.Icon, popup: L.Content): L.Marker {
    const marker = L.marker(coordinates).bindPopup('');
    marker.setIcon(icon);
    marker.bindPopup(popup, { minWidth: MapInfo.POPUP_MIN_WIDTH });
    return marker;
  }

  addMarker(marker) {
    this.markerLayers.push(marker);
  }

  removeMarker(marker) {
    if(marker){
      marker.remove();
    }
  }

  /***********************  DRAW  *****************************/

  addDrawLayer(layer: L.Layer) {
    this.drawLayer.addLayer(layer);
  }

  removeDrawLayer(layer: L.Layer) {
    this.drawLayer.removeLayer(layer);
  }


  removeDrawLayers() {
    this.drawLayer.clearLayers();
  }

  getDrawLayers(): L.Layer [] {
    return this.drawLayer.getLayers();
  }

  fitDrawLayer() {
    const bounds = this.drawLayer.getBounds();
    if (bounds && bounds.isValid())
      this.map.fitBounds(bounds, {animate: true});
  }

  /***********************  GENERIC LAYERS  *****************************/

  addLayerToMap(layer: L.Layer) {
    if(!layer || !this.map) return;

    if (!this.map.hasLayer(layer)) {
      this.map.addLayer(layer);
    }
  }

  /** Remove um objeto geográfico ao mapa */
  removeLayerFromMap(layer: L.Layer) {
    if(!layer || !this.map) return;

    if (this.map.hasLayer(layer)) {
      this.map.removeLayer(layer);
    }
  }

  hasLayer(layer: L.Layer): boolean {
    if(!layer || !this.map) return false;

    return this.map.hasLayer(layer);
  }

  /***********************  OPACITY  *****************************/

  setGeoLayerOpacity(id: string, layer: L.FeatureGroup, opacity: number) {
    if ( (id === this.BAND_ID) || (id === this.GASDUCT_ID) || (id === this.OILDUCT_ID || (id === this.SIMF_ID) )) { // Linhas
      layer.setStyle({ opacity: opacity });
    }
    else { // Markers
      layer.invoke('setOpacity', opacity);
    }
  }

  updateObjectsOpacity(opacity: number){
    for (const id in this.geoLayers) {
      let layer = this.geoLayers[id];
      this.setGeoLayerOpacity(id, layer, opacity);
    }

    this.userTrackingLayer.invoke('setOpacity', opacity);
    this.vehicleTrackingLayer.invoke('setOpacity', opacity);

    this.opacity = opacity;
  }

  /***********************  MENU DE CONTEXTO  *****************************/

  private onCreateEvent(e) {
    this.logger.debug('BaseMapComponent.onCreateEvent()');
    const marker: L.Marker = e.relatedTarget;
    this.eventClicked.emit(marker);
  }

  private onCreateVerification(e) {
    this.logger.debug('BaseMapComponent.onCreateVerification()');
    const marker: L.Marker = e.relatedTarget;
    this.verificationClicked.emit(marker);
  }
}
