<form class=" edit-form h-100">
  <div class="sipd-container">

    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVO]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button matTooltip="Habilitar Edição" [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary" matTooltip="Copiar" (click)="onCopyClick()" [disabled]="!id"
          [hidden]='!authorizationService.isProfileCreateEditAllowedForUser()'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()" matTooltip="Remover"
          [hidden]='!authorizationService.isProfileCreateEditAllowedForUser()'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações"
          [hidden]='!authorizationService.isProfileCreateEditAllowedForUser()'>
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button (click)="onSaveClick($event)" [disabled]="!canSave()" matTooltip="Salvar"
            [hidden]='!authorizationService.isProfileCreateEditAllowedForUser()'>
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div class="d-grid">
        <div class="grid-item full-line ">
          <div class="grid-item-header">
            <h5 class="user-edit-label">Informações do Perfil</h5>
          </div>
          <mat-divider></mat-divider>
          <div class="grid-item-content" fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Nome</mat-label>
              <input cdkFocusInitial matInput name="name" placeholder="Nome" [(ngModel)]="view['name']"
                oninput="value = value.trimLeft()" required [readonly]="readOnly">
              <mat-error>Campo Obrigatório</mat-error>
            </mat-form-field>
            <single-autocomplete class="no-hint-text" [data]="categoryMap | keyvalue: originalOrder"
              placeholder="Categoria" name="value" optionValue="key" [(valueData)]="view['userType']" [required]="true"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
          </div>
        </div>
        <div class="grid-item full-line">
          <div class="grid-item-header">
            <div>
              <mat-slide-toggle [disabled]="readOnly" name="acces_app"
                [checked]="permissionsMap.get('ACCES_APP') ? true : false" (change)="changeMap($event, 'ACCES_APP')">
                <mat-label><h5 class="user-edit-label">Acesso ao Aplicativo</h5></mat-label>
              </mat-slide-toggle>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="grid-item full-line">
          <div class="grid-item-header">
            <div>
              <mat-slide-toggle [disabled]="readOnly" name="acces_web"
                [checked]="permissionsMap.get('ACCES_WEB') ? true : false" (change)="changeMap($event, 'ACCES_WEB')">
                <mat-label> <h5 class="user-edit-label">Acesso à Web</h5></mat-label>
              </mat-slide-toggle>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="grid-item full-line d-grid">
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Lotações</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="ACCESS_TO_ALL_PLACEMENT"
                  [checked]="permissionsMap.get('ACCESS_TO_ALL_PLACEMENT') ? true : false"
                  (change)="changeMap($event, 'ACCESS_TO_ALL_PLACEMENT')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Acesso a dados de todas as Lotações</mat-label>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Alertas</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_alerts" [checked]="permissionsMap.get('LIST_ALERTS') ? true : false"
                  (change)="changeMap($event, 'LIST_ALERTS')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <label class="small w-100 mb-2"
                    [attr.disabled]="(permissionsMap.get('LIST_ALERTS') ? false : true) || readOnly">Origens:</label>
                  <mat-radio-group [disabled]="(permissionsMap.get('LIST_ALERTS') ? false : true) || readOnly"
                    (change)="alertRadioChange()" [(ngModel)]="alertPermissionOptions.option" name="alerRadioGroup">
                    <mat-radio-button class="mr-4" [value]="permisionRuleOption.ALL_FIELD">Todas</mat-radio-button>
                    <mat-radio-button [value]="permisionRuleOption.SOME_FIELDS">Selecionar</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="form-spacer">
                  <multiselect-autocomplete class="no-hint-text" [data]="alertTypeMap | keyvalue: originalOrder"
                    placeholder="Selecionar Origens" name="value" optionValue="key"
                    [(valueData)]="alertPermissionOptions.fields" [disabled]="disableAlertList || readOnly"
                    (selectionChangedEvent)="selectAlertFields()">
                  </multiselect-autocomplete>
                </div>
                <div class="form-spacer">
                  <mat-slide-toggle name="edit_alert" [checked]="permissionsMap.get('EDIT_ALERT') ? true : false"
                    (change)="changeMap($event, 'EDIT_ALERT')"
                    [disabled]="(permissionsMap.get('LIST_ALERTS') ? false : true) || readOnly">
                    <mat-label>Editar</mat-label>
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Rastreamento</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="view_tracking_team"
                  [checked]="permissionsMap.get('VIEW_TRACKING_TEAM') ? true : false"
                  (change)="changeMap($event, 'VIEW_TRACKING_TEAM')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar Equipes</mat-label>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Canais</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <multiselect-autocomplete class="no-hint-text" [data]="channelTypeDescription | keyvalue:originalOrder"
                placeholder="Selecionar Canais" name="value" optionValue="key"
                [(valueData)]="channelPermisionOptions.fields" (selectionChangedEvent)="selectChannelFields()"
                [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                <mat-label>Participa dos Tipos de Canais:</mat-label>
              </multiselect-autocomplete>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Mapa</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="view_map_dc_history_id"
                  [checked]="permissionsMap.get('VIEW_MAP_DC_HISTORY_ID') ? true : false"
                  (change)="changeMap($event, 'VIEW_MAP_DC_HISTORY_ID')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar DCs</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-spacer">
                <mat-slide-toggle name="view_map_observed_area_id"
                  [checked]="permissionsMap.get('VIEW_MAP_OBSERVED_AREA_ID') ? true : false"
                  (change)="changeMap($event, 'VIEW_MAP_OBSERVED_AREA_ID')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar Áreas Observadas</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-spacer">
                <mat-slide-toggle name="view_map_team" [checked]="permissionsMap.get('VIEW_MAP_TEAM') ? true : false"
                  (change)="changeMap($event, 'VIEW_MAP_TEAM')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar Equipes</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-spacer" [hidden]="true">
                <mat-slide-toggle name="view_map_simf" [checked]="permissionsMap.get('VIEW_MAP_SIMF') ? true : false"
                  (change)="changeMap($event, 'VIEW_MAP_SIMF')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar SIMF</mat-label>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Eventos</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_events" [checked]="permissionsMap.get('LIST_EVENTS') ? true : false"
                  (change)="changeMap($event, 'LIST_EVENTS')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>

              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_event"
                    [checked]="permissionsMap.get('CREATE_EDIT_EVENT') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_EVENT')"
                    [disabled]="(permissionsMap.get('LIST_EVENTS') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>

                <div class="form-indented">
                  <div class="form-spacer">

                    <mat-slide-toggle name="delete_event" [checked]="permissionsMap.get('DELETE_EVENT') ? true : false"
                      (change)="changeMap($event, 'DELETE_EVENT')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer">

                    <mat-slide-toggle name="create_edit_event_notes"
                      [checked]="permissionsMap.get('CREATE_EDIT_EVENT_NOTES') ? true : false"
                      (change)="changeMap($event, 'CREATE_EDIT_EVENT_NOTES')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || readOnly">
                      <mat-label>Criar/Editar Notas</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer">

                    <mat-slide-toggle name="delete_event_notes"
                      [checked]="permissionsMap.get('DELETE_EVENT_NOTES') ? true : false"
                      (change)="changeMap($event, 'DELETE_EVENT_NOTES')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_EVENT_NOTES') ? false : true) || readOnly">
                      <mat-label>Remover Notas</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer">

                    <mat-slide-toggle name="change_events_from_any_source"
                      [checked]="permissionsMap.get('CHANGE_EVENTS_FROM_ANY_SOURCE') && sourcePermissionOptions.option===permisionRuleOption.ALL_SOURCE "
                      (change)="changeMap($event, 'CHANGE_EVENTS_FROM_ANY_SOURCE')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || readOnly">
                      <mat-label>Alterar eventos de qualquer origem</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer form-indented">
                    <multiselect-autocomplete class="no-hint-text"
                      [data]="singleDataCacheService.getValues('ReportSource')" placeholder="Selecionar Origens"
                      label="Alterar somente eventos de origem:" [(valueData)]="sourcePermissionOptions.fields"
                      (selectionChangedEvent)="selectSourceFields()"
                      [disabled]="((permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || sourcePermissionOptions.option===permisionRuleOption.ALL_SOURCE) || readOnly">
                    </multiselect-autocomplete>
                  </div>
                  <div class="form-spacer">

                    <mat-slide-toggle name="change_any_event_field"
                      [checked]="permissionsMap.get('CHANGE_ANY_EVENT_FIELD') && eventPermissionOptions?.option===permisionRuleOption.ALL_FIELD"
                      (change)="changeMap($event, 'CHANGE_ANY_EVENT_FIELD')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || readOnly">
                      <mat-label>Alterar qualquer campo</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer form-indented">
                    <multiselect-autocomplete class="no-hint-text"
                      [data]="eventFieldsDescription | keyvalue:originalOrder" placeholder="Selecionar Campos"
                      label="Campos que não podem ser alterados:" name="value" optionValue="key"
                      [(valueData)]="eventPermissionOptions.fields" (selectionChangedEvent)="selectEventsFields()"
                      [disabled]="((permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || eventPermissionOptions?.option===permisionRuleOption.ALL_FIELD) || readOnly">
                    </multiselect-autocomplete>
                  </div>
                  <div class="form-spacer">
                    <mat-slide-toggle name="edit_end_event"
                      [checked]="permissionsMap.get('EDIT_END_EVENT') ? true : false"
                      (change)="changeMap($event, 'EDIT_END_EVENT')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_EVENT') ? false : true) || readOnly">
                      <mat-label>Alterar eventos concluídos</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Verificações</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_verifications"
                  [checked]="permissionsMap.get('LIST_VERIFICATIONS') ? true : false"
                  (change)="changeMap($event, 'LIST_VERIFICATIONS')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_verifications"
                    [checked]="permissionsMap.get('CREATE_EDIT_VERIFICATIONS') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_VERIFICATIONS')"
                    [disabled]="(permissionsMap.get('LIST_VERIFICATIONS') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_verifications"
                      [checked]="permissionsMap.get('DELETE_VERIFICATIONS') ? true : false"
                      (change)="changeMap($event, 'DELETE_VERIFICATIONS')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_VERIFICATIONS') ? false : true) || readOnly">

                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer">
                    <mat-radio-group aria-labelledby="period-group-label"
                      [disabled]="(permissionsMap.get('DELETE_VERIFICATIONS') ? false : true) || readOnly"
                      [(ngModel)]="verificationPermisionOptions.option" name="verificationOption"
                      (change)="verificationRadioChange()">
                      <mat-radio-button class="mr-4" color="accent"
                        [value]="permisionRuleOption.ALL_VERIFICATIONS">Todas</mat-radio-button>
                      <mat-radio-button class="mr-4" color="accent"
                        [value]="permisionRuleOption.ONLY_NOT_STARTED">Somente
                        não
                        enviadas</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Rondas</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_patrols" [checked]="permissionsMap.get('LIST_PATROLS') ? true : false"
                  (change)="changeMap($event, 'LIST_PATROLS')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>

              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_patrol"
                    [checked]="permissionsMap.get('CREATE_EDIT_PATROL') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_PATROL')"
                    [disabled]="(permissionsMap.get('LIST_PATROLS') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_patrol"
                      [checked]="permissionsMap.get('DELETE_PATROL') ? true : false"
                      (change)="changeMap($event, 'DELETE_PATROL')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_PATROL') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer">
                    <mat-radio-group aria-labelledby="period-group-label"
                      [disabled]="(permissionsMap.get('DELETE_PATROL') ? false : true) || readOnly"
                      (change)="patrolRadioChange()" [(ngModel)]="patrolPermisionOptions.option"
                      name="patrolStatusOption">
                      <mat-radio-button class="mr-4" color="accent"
                        [value]="permisionRuleOption.ALL_PATROLS">Todas</mat-radio-button>
                      <mat-radio-button class="mr-4" color="accent"
                        [value]="permisionRuleOption.ONLY_NOT_STARTED">Somente
                        não
                        iniciadas</mat-radio-button>
                      <mat-label></mat-label>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Usuários</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_users_administration"
                  [checked]="permissionsMap.get('LIST_USERS_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_USERS_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="list_users" [checked]="permissionsMap.get('CREATE_EDIT_USER') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_USER')"
                    [disabled]="(permissionsMap.get('LIST_USERS_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_user" [checked]="permissionsMap.get('DELETE_USER') ? true : false"
                      (change)="changeMap($event, 'DELETE_USER')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_USER') ? false : true) || readOnly">

                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                  <div class="form-spacer" matTooltip="Perfis que podem ser modificados pelo perfil atual">
                    <label class="small w-100 mb-2" for="profileRadioGroup"
                      [attr.disabled]="((permissionsMap.get('DELETE_USER') || permissionsMap.get('CREATE_EDIT_USER')) ? false : true) || readOnly">
                      Perfis</label>
                    <mat-radio-group aria-labelledby="period-group-label"
                      [disabled]="((permissionsMap.get('DELETE_USER') || permissionsMap.get('CREATE_EDIT_USER')) ? false : true) || readOnly"
                      (change)="profilesRadioChange()" [(ngModel)]="profilePermisionOptions.option"
                      name="profileRadioGroup">
                      <mat-radio-button class="mr-4" color="accent"
                        [value]="permisionRuleOption.ALL_PROFILE">Todos</mat-radio-button>
                      <mat-radio-button class="mr-4" color="accent"
                        [value]="permisionRuleOption.SOME_PROFILE">Selecionar</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="form-spacer">
                    <multiselect-autocomplete class="no-hint-text" [data]="profileMap | keyvalue: originalOrder"
                      placeholder="Selecionar Perfis" name="value" optionValue="key"
                      [(valueData)]="profilePermisionOptions.fields" (selectionChangedEvent)="selectProfileFields()"
                      [disabled]="disableProfileList || readOnly">
                    </multiselect-autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Equipes</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_patrol_team_administration"
                  [checked]="permissionsMap.get('LIST_PATROL_TEAM_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_PATROL_TEAM_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>

              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_patrol_team"
                    [checked]="permissionsMap.get('CREATE_EDIT_PATROL_TEAM') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_PATROL_TEAM')"
                    [disabled]="(permissionsMap.get('LIST_PATROL_TEAM_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_patrol_team"
                      [checked]="permissionsMap.get('DELETE_PATROL_TEAM') ? true : false"
                      (change)="changeMap($event, 'DELETE_PATROL_TEAM')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_PATROL_TEAM') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Lotações</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_placement_administration"
                  [checked]="permissionsMap.get('LIST_PLACEMENT_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_PLACEMENT_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_placement"
                    [checked]="permissionsMap.get('CREATE_EDIT_PLACEMENT') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_PLACEMENT')"
                    [disabled]="(permissionsMap.get('LIST_PLACEMENT_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_placement"
                      [checked]="permissionsMap.get('DELETE_PLACEMENT') ? true : false"
                      (change)="changeMap($event, 'DELETE_PLACEMENT')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_PLACEMENT') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Empresas</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_company_administration"
                  [checked]="permissionsMap.get('LIST_COMPANY_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_COMPANY_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_company"
                    [checked]="permissionsMap.get('CREATE_EDIT_COMPANY') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_COMPANY')"
                    [disabled]="(permissionsMap.get('LIST_COMPANY_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_company"
                      [checked]="permissionsMap.get('DELETE_COMPANY') ? true : false"
                      (change)="changeMap($event, 'DELETE_COMPANY')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_COMPANY') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Veículos</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="LIST_VEHICLE_ADMINISTRATION"
                  [checked]="permissionsMap.get('LIST_VEHICLE_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_VEHICLE_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>

              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_vehicle"
                    [checked]="permissionsMap.get('CREATE_EDIT_VEHICLE') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_VEHICLE')"
                    [disabled]="(permissionsMap.get('LIST_VEHICLE_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_vehicle"
                      [checked]="permissionsMap.get('DELETE_VEHICLE') ? true : false"
                      (change)="changeMap($event, 'DELETE_VEHICLE')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_VEHICLE') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Ações Principais</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_main_event_administration"
                  [checked]="permissionsMap.get('LIST_MAIN_EVENT_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_MAIN_EVENT_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_main_event"
                    [checked]="permissionsMap.get('CREATE_EDIT_MAIN_EVENT') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_MAIN_EVENT')"
                    [disabled]="(permissionsMap.get('LIST_MAIN_EVENT_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_main_event"
                      [checked]="permissionsMap.get('DELETE_MAIN_EVENT') ? true : false"
                      (change)="changeMap($event, 'DELETE_MAIN_EVENT')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_MAIN_EVENT') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Áreas Observadas</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_observed_area_administration"
                  [checked]="permissionsMap.get('LIST_OBSERVED_AREA_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_OBSERVED_AREA_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_observed_area"
                    [checked]="permissionsMap.get('CREATE_EDIT_OBSERVED_AREA') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_OBSERVED_AREA')"
                    [disabled]="(permissionsMap.get('LIST_OBSERVED_AREA_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_observed_area"
                      [checked]="permissionsMap.get('DELETE_OBSERVED_AREA') ? true : false"
                      (change)="changeMap($event, 'DELETE_OBSERVED_AREA')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_OBSERVED_AREA') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Formulários</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_forms_administration"
                  [checked]="permissionsMap.get('LIST_FORMS_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_FORMS_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_form"
                    [checked]="permissionsMap.get('CREATE_EDIT_FORM') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_FORM')"
                    [disabled]="(permissionsMap.get('LIST_FORMS_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_main_actions"
                      [checked]="permissionsMap.get('DELETE_FORM') ? true : false"
                      (change)="changeMap($event, 'DELETE_FORM')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_FORM') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Pontos</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_points_administration"
                  [checked]="permissionsMap.get('LIST_POINTS_ADMINISTRATION') ? true : false"
                  (change)="changeMap($event, 'LIST_POINTS_ADMINISTRATION')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="create_edit_point"
                    [checked]="permissionsMap.get('CREATE_EDIT_POINT') ? true : false"
                    (change)="changeMap($event, 'CREATE_EDIT_POINT')"
                    [disabled]="(permissionsMap.get('LIST_POINTS_ADMINISTRATION') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>

                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_point" [checked]="permissionsMap.get('DELETE_POINT') ? true : false"
                      (change)="changeMap($event, 'DELETE_POINT')"
                      [disabled]="(permissionsMap.get('CREATE_EDIT_POINT') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item" [attr.disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
            <div class="grid-item-header">
              <h6 class="user-edit-label">Administração de Cadastros</h6>
            </div>
            <mat-divider></mat-divider>
            <div class="grid-item-content">
              <div class="form-spacer">
                <mat-slide-toggle name="list_registration_group"
                  [checked]="permissionsMap.get('LIST_REGISTRATION_GROUP') ? true : false"
                  (change)="changeMap($event, 'LIST_REGISTRATION_GROUP')"
                  [disabled]="(permissionsMap.get('ACCES_WEB') ? false : true) || readOnly">
                  <mat-label>Visualizar</mat-label>
                </mat-slide-toggle>
              </div>
              <div class="form-group">
                <div class="form-spacer">
                  <mat-slide-toggle name="edit_registration_group"
                    [checked]="permissionsMap.get('EDIT_REGISTRATION_GROUP') ? true : false"
                    (change)="changeMap($event, 'EDIT_REGISTRATION_GROUP')"
                    [disabled]="(permissionsMap.get('LIST_REGISTRATION_GROUP') ? false : true) || readOnly">
                    <mat-label>Criar/Editar</mat-label>
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>

                <div class="form-indented">
                  <div class="form-spacer">
                    <mat-slide-toggle name="delete_registration_group"
                      [checked]="permissionsMap.get('DELETE_REGISTRATION_GROUP') ? true : false"
                      (change)="changeMap($event, 'DELETE_REGISTRATION_GROUP')"
                      [disabled]="(permissionsMap.get('EDIT_REGISTRATION_GROUP') ? false : true) || readOnly">
                      <mat-label>Remover</mat-label>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>