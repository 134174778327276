import { MatDialog } from '@angular/material/dialog';
import {Component, OnInit, OnDestroy, Inject, NgZone, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {PatrolModel} from '../../../model/patrol.model';
import {PatrolService} from '../../../service/model/patrol.service';
import {environment} from '../../../../environments/environment';
import { ShiftDescription, OperationStatus, UserType, OperationType, ServiceTypeDescription } from '../../../model/enums.enum';
import { saveAs } from 'file-saver/dist/FileSaver';
import { BooleanBRPipe } from '../../../general/boolean-br.pipe';
import { AuthorizationService } from '../../../service/authorization/authorization.service';
import { StorageService } from '../../../service/storage-service';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import * as GoldenLayout from 'golden-layout';
import { ToastrService } from 'ngx-toastr';
import DateUtils from 'src/app/service/util/date-utils';
import { TrackingService } from 'src/app/service/model/tracking.service';
import { PatrolFilterDialogComponent } from '../patrol-filter-dialog/patrol-filter-dialog.component';
import { PatrolFilterModel } from '../patrol-filter-dialog/patrol.filter.model';
import { OperationListComponent } from '../../operation-list-component';
import { EntityModel } from 'src/app/model/entity.model';
import { PatrolRepetitionDialogComponent } from '../patrol-repetition-dialog/patrol-repetition-dialog.component';
import { PatrolRepetitionModel } from 'src/app/model/patrol.repetition.model';
import { first } from 'rxjs/operators';
import { OperationModel } from 'src/app/model/operation.model';
import { ConfirmationDialogComponent } from 'src/app/general/confirmation-dialog/confirmation-dialog.component';
import { PatrolRepetitionFilterModel } from '../patrol-filter-dialog/patrol-repetition.filter.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { ESP, HttpError } from 'src/app/common/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { MarkersService } from 'src/app/service/model/markers.service';

@ProfileClassToConsole()
@Component({
  selector:    'app-patrols-list',
  templateUrl: './patrol-list.component.html',
  styleUrls:   [ '../../../app.component.scss', '../../list.component.scss', './patrol-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatrolListComponent extends OperationListComponent implements OnInit, OnDestroy {

  shiftDescription = ShiftDescription;

  patrolRepetitions: PatrolRepetitionModel[] = [];

  serviceTypeDescription = ServiceTypeDescription;

  isHiddenButtonCreateEditPatrol: boolean;
  isHiddenButtonDeletePatrol: boolean;
  
  constructor(logger:                      NGXLogger,
              protected changeDetector:    ChangeDetectorRef,
              protected ngZone:            NgZone,
              protected patrolService:     PatrolService,
              protected toastr:            ToastrService,
              protected booleanPipe:         BooleanBRPipe,
              public authorizationService: AuthorizationService,
              protected trackingService: TrackingService,
              protected markerService:   MarkersService,
              protected storageService:  StorageService,
              dialog: MatDialog,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, changeDetector, ngZone, authorizationService, patrolService, OperationType.PATROL, dialog, 'patrols', environment.PATROL_GROUP_LABEL, environment.PATROL_TITLE_LABEL,
      environment.PATROL_MODEL_LABEL, storageService, trackingService, markerService, toastr, goldenLayout, container);

    logger.debug('PatrolListComponent.constructor()');
  }

  ngOnInit() {
    super.ngOnInit();

    //Utilizado para reload da listagem de rondas somente após edição de rondas previstas
    this.subscribeLoadRecordsFromServer();

    this.logger.debug('PatrolListComponent.ngOnInit()');

    // Ao editar essa lista, não esquecer de atualizar columnsTitles mais abaixo (select e lastSync não entram, date=>data+hora)
    this.displayedColumns = ['select',
                             'identifier',
                             'status',
                             'name',
                             'startDate',
                             'patrolTeam.name',                             
                             'priority',
                             'band',
                             'km',
                             'patrolInspectionType',
                             'patrolTeam.company.name',
                             'patrolTeam.serviceType',
                             'patrolTeam.vehicle.plate',
                             'author',                             
                             'lastSync'];

  }

  ngOnDestroy(){
    this.glUnSubscribeEvent("updatePatrolRepetitionList");
  }

  subscribeLoadRecordsFromServer(){
    this.glSubscribeEvent("updatePatrolRepetitionList", () => {
      this.logger.debug('PatrolListComponent.subscribeLoadRecordsFromServer(): reload da listagem de rondas após edição de ronda prevista');
      this.loadRecordsFromServer();
    });
  }

  protected getDefaultSortColumn(): string {
    return "identifier";
  }

  protected getDefaultSortDirection(): 'asc' | 'desc' {
    return 'desc';
  }

  protected newFilterModel() {
    return new PatrolFilterModel();
  }

  postLoadProcess() {
    this.model.forEach((patrol: PatrolModel) => {
      patrol.type = OperationType.PATROL;
      this.updateOperationFromPatrolTeam(patrol);
    });
    
    this.loadLastSignals();
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(PatrolFilterDialogComponent, {
      width: '800px',
      data: this.filterModel,
      panelClass: 'sipd-modal'

    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.updateDataSource(result);
      }
    });
  }

  onEditClick(row: any, event: Event) {
    let options = null;
    if(this.isPredicted(row)) {
      options = {predicted: true, startDate: row.startDate, referencePatrolId: row.id};
    }

    const componentName = this.componentName + '-edit';
    this.glOpenContainer(componentName, {id: row.id, model: row, options:options});
  }

  excludePredictedPatrol(patrolRepetition : PatrolRepetitionModel, patrolStartDate: number) {
    let excludedDate: number = DateUtils.getStartOfDayUTC(patrolStartDate);
    if(!patrolRepetition.excludedRepetitions) patrolRepetition.excludedRepetitions = [];
    patrolRepetition.excludedRepetitions.push(excludedDate.toString());

    this.patrolService.updatePatrolRepetition(patrolRepetition).pipe(first()).subscribe(() => {
      this.toastr.success("Ronda Prevista removida com sucesso", "Remover");
      this.loadRecordsFromServer();
    },
    err => {
      this.logger.error("Falha ao Remover Ronda Prevista " + JSON.stringify(err));
      this.toastr.error("Falha ao Remover Ronda Prevista", "Remover");
    });
  }

  onDeleteClick(row: any, identifier: string, event: any) {
    let patrol = row as PatrolModel;
    if(this.isPredicted(patrol)) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data: {
          msg: 'Remover ' + this.modelName + '?',
          title: 'Remover ' + this.modelName,
          okLabel: 'Remover',
          identifier: identifier,
          showIdentifier: true
        }
      });
      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          this.logger.debug('PatrolListComponent.onDeleteClick()');
          this.patrolService.getPatrolRepetitionByReferencePatrolId(row.id).pipe(first()).subscribe((patrolRepetitionModel : PatrolRepetitionModel)=>{            
            this.excludePredictedPatrol(patrolRepetitionModel, patrol.startDate);
          },(error: HttpErrorResponse) => {
              if(error.status === HttpError.NOT_FOUND){
                this.toastr.error('Erro ao recuperar a repetição de ronda');
              }
              this.logger.error(error);
          });
        }
      });
    } else {
      super.onDeleteClick(row, identifier, event);
    }
  }

  onPatrolRepetitionClick(row: EntityModel) {
    const dialogRepetitionRef = this.dialog.open(PatrolRepetitionDialogComponent, {
      width: '700px',
      panelClass: 'sipd-modal',
      data: row
    });

    dialogRepetitionRef.afterClosed().pipe(first()).subscribe( (patrolRepetitionModel:PatrolRepetitionModel) => {
      if(!patrolRepetitionModel) return;

      if(patrolRepetitionModel.active == false) { // Remover a repetição
        this.patrolService.deletePatrolRepetition(patrolRepetitionModel).pipe(first()).subscribe(() => {
          this.toastr.success("Repetição removida com sucesso.");
          this.loadRecordsFromServer();
        },
        err =>{
          this.logger.error("Erro ao DESATIVAR regra de repetição " + JSON.stringify(err));
          this.toastr.error("Falha ao remover repetição");
        });
      }
      else { // criar ou atualizar a repetição
        if(patrolRepetitionModel.id) { // atualizar
          this.patrolService.updatePatrolRepetition(patrolRepetitionModel).pipe(first()).subscribe(() => {
            this.toastr.success("Repetição atualizada com sucesso", "Edição");
            this.loadRecordsFromServer();
          },
          err => {
            this.logger.error("Erro ao EDITAR regra de repetição " + JSON.stringify(err));
            this.toastr.error("Falha ao atualizar repetição");
          });
        } else { // Criar
          this.patrolService.createPatrolRepetition(patrolRepetitionModel).pipe(first()).subscribe((createdPatrolRepetition) => {
            this.toastr.success("Repetição criada com sucesso", "Criação");
            this.loadRecordsFromServer();
          },
          err =>{
            this.logger.error("Erro ao CRIAR regra de repetição " + JSON.stringify(err));
            this.toastr.error("Falha ao criar repetição");
          });
        }
      }
    }, err => {
      this.logger.error("Erro ao FECHAR o diálogo de repetições " + JSON.stringify(err));
    });
  }

  protected getExportColValue(col, model):string[] {
    let values: string[] = [];
    if(col == 'startDate') {
      values.push(model[col] ? DateUtils.timestampToStringInDays(model[col]) : "");
      values.push(model[col] ? DateUtils.timestampToTimeString(model[col]) : "");
    }
    else if(col == 'priority') {
      if(model[col]) values.push('Sim');
      else values.push('Não');
    }
    else if(col == 'status') {
      values.push( this.getOperationStatus(model));
    }
    else if(col == 'patrolTeam.vehicle.plate') {
      if(model['patrolTeam'] && model['patrolTeam']['vehicle']) values.push(model['patrolTeam']['vehicle']['plate']);
      else values.push("");
    }
    else if(col == 'patrolTeam.name') {
      if(model['patrolTeam']) values.push(model['patrolTeam']['name']);
      else values.push("");
    }
    else if(col == 'patrolTeam.shift') {
      if(model['patrolTeam'] &&  model['patrolTeam']['shift']) values.push(ShiftDescription[model['patrolTeam']['shift']]);
      else values.push("");
    }
    else if(col == 'patrolTeam.company.name') {
      if(model['patrolTeam'] &&  model['patrolTeam']['company']) values.push(model['patrolTeam']['company']['name']);
      else values.push("");
    }
    else if(col == 'patrolTeam.serviceType') {
      values.push(this.getServiceTypeDescription(model));
    }
    else if(col == 'author') {
      if(model['author']) values.push(this.getUserNameLoginTitle(model['author']));
      else values.push("");
    }
    else {
      values.push(model[col]);
    }
    return values;
  }

  onExportManyClick() {
    if(this.selection.selected.length == 1) {
      this.onExportClick(this.selection.selected[0], null);
    }
    else {
      const columnsTitles = ['ID', 'Status', 'Nome', 'Data', 'Hora', 'Equipe', 'Bloqueada', 'Faixa', 'KM', 'Tipo de Inspeção', 'Empresa', 'Tipo de Serviço', 'Placa', 'Planejador'];
      this.exportXls(columnsTitles);
    }
  }

  onExportClick(row: any, event: any) {
    this.loadingListService.loadingOn();
    this.patrolService.exportPatrol(row).pipe(first()).subscribe(response => {
      this.loadingListService.loadingOff();
      this.renderComponent();

      let filename = `${row.identifier}.zip`;
      filename = filename.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      saveAs(response, filename);
    },
    error =>{
      this.loadingListService.loadingOff();
      this.renderComponent();
      this.logger.error(error);
    });
  }

  canDelete(entity: EntityModel) {
    return this.authorizationService.isPatrolDeletionAllowed(<PatrolModel>entity);
  }

  isPredicted(entity: EntityModel){
    let operation: OperationModel = entity as OperationModel;
    if(operation?.identifier?.endsWith('PREVISTA')) {
      return true;
    }
    else{
      return false;
    }
  }

  canHasHistoricalTracking(operation: OperationModel){
    if(this.isPredicted(operation)) {
      return false;
    }
    return super.canHasHistoricalTracking(operation);
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   */
  protected getStringEntityForFilter(patrol: PatrolModel): string {
    const startDate = (patrol.startDate) ? this.formatDate(patrol.startDate) : '';
    const priority = patrol.priority ? 'sim': 'não';
    const vehiclePlate = (patrol.patrolTeam && patrol.patrolTeam.vehicle)? super.lowerAndTrimText(patrol.patrolTeam.vehicle.plate) : '';
    const operationStatus = super.lowerAndTrimText(this.getOperationStatus(patrol) + ((patrol.expired && patrol.status=='FINISHED')? ' (expirada)': ''));
    const teamName = (patrol.patrolTeam) ? super.lowerAndTrimText(patrol.patrolTeam.name) : '';
    const identifier = super.lowerAndTrimText(patrol.identifier);
    const name = super.lowerAndTrimText(patrol.name);
    const band = super.lowerAndTrimText(patrol.band);
    const km = patrol.stretchStartKM && patrol.stretchEndKM ? patrol.stretchStartKM + ' - ' +
               patrol.stretchEndKM : (patrol.km ? patrol.km : '');
    const company = patrol.patrolTeam && patrol.patrolTeam.company ? super.lowerAndTrimText(patrol.patrolTeam.company.name): '';
    const author = (patrol.author) ? super.lowerAndTrimText(this.getUserNameLoginTitle(patrol.author)) : '';
    const inspectionType = super.lowerAndTrimText(patrol.patrolInspectionType);
    const serviceType = super.lowerAndTrimText(this.getServiceTypeDescription(patrol));
    return company + ESP + band + ESP +  identifier + ESP + startDate + ESP + priority + ESP + vehiclePlate + ESP + operationStatus + ESP + teamName + ESP + name + ESP + km + ESP + author + ESP + inspectionType + ESP + serviceType;
  }

  // Verifica os requisitos do model para arquivamento
  hasArchivalRequirements(patrol: PatrolModel){

    if (this.isPredicted(patrol)) {
      return false;
    }

    if(patrol.status != OperationStatus.FINISHED){ // Verifica se o status é Concluído
      return false;
    }

    if(this.authorizationService.isControl()){ // Se for perfil ANALYSIS_CCPD, verifica se as rondas foram criados por ele
      if(!patrol.author || patrol.author.id !== this.loggedUser.id.toString()){
        return false;
      }
    }

    return true;
  }

  archiveTooltipText() {
    return `${this.archiveButtonLabel} Ronda(s) Selecionada(s)`;
  }

  saveData(entity){
    // Confere se o usuário tem acesso apenas algumas lotações, antes de atualizar na lista 
    let specificPlacements = this.storageService.getSpecificPlacementIds();
    const operation = entity as OperationModel;
    if(specificPlacements.length > 0 && !specificPlacements.includes(operation.placement.id)){
      return;
    }
    
    let patrol: PatrolModel = entity as PatrolModel;
    if (patrol.patrolRepetitionId){
      // Uma ronda que tem repetição foi atualizada, precisamos atualizar a lista inteira
      this.loadRecordsFromServer();
      return;
    }

    const oldModel: any = this.model.find( model => operation.id == model.id);
    if (oldModel)
      entity.lastSync = oldModel.lastSync;

    super.saveData(entity);
  }

  getServiceTypeDescription(operation: OperationModel): string {
    if (!operation) return '';   
    
    return  operation.patrolTeam?.serviceType ? this.  serviceTypeDescription[operation.patrolTeam.serviceType] : '';
    
  }

  /** Remoção de multiplas rondas, precisa analizar se é uma repetição de ronda ou não */
  onDeleteManyClick(rows: EntityModel[], event?: any ) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {        
          msg: 'Remover ' + rows.length + ' item(s) selecionado(s)?',
          title: 'Remover Ronda',
          okLabel: 'Remover',
          showIdentifier: false
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.logger.debug('PatrolListComponet.onDeleteManyClick()');
        let predictedPatrols: PatrolModel[] = []; 
        let patrols: PatrolModel[] = []; 
        
        rows.forEach((patrol:PatrolModel) => {
          if(patrol.identifier.includes("PREVISTA")){
            predictedPatrols.push(patrol)
          }
          else{
            patrols.push(patrol);
          }      
        });

        if(predictedPatrols.length > 0){
          this.updatePredictedPatrols(predictedPatrols);
        }
    
        if(patrols.length > 0){
          this.deleteSelectedEntities(patrols);
          this.postListDeleteProcess(patrols);
        }
      }
    });
  }

  /** 1. Agrupa as rondas que pertecem a uma misma repetição 
   *  2. Busca a lista de repetiçoes no backend
   *  3. Atualiza a lista de repetições a serem excluidas 
   * @param predictedPatrols 
   */
  updatePredictedPatrols(predictedPatrols: PatrolModel[]){
    this.logger.debug('PatrolListComponet.updatePredictedPatrols()');
    
    // Agrupa por ReferenceId
    let predictedPatrolsByReferenceIdMap: Map<string, PatrolModel[]> = new Map<string, PatrolModel[]>();
    for(let patrol of predictedPatrols){     
      if(!predictedPatrolsByReferenceIdMap.has(patrol.id)){
        const patrolsWithSameReferenceId = predictedPatrols.filter(p => {return p.id === patrol.id});
        predictedPatrolsByReferenceIdMap.set(patrol.id, patrolsWithSameReferenceId);
      }
    }

    let filter: PatrolRepetitionFilterModel = new PatrolRepetitionFilterModel();

    // Busca todas as repetições a partir dos ReferenceIds
    let ids = []; 
    for (var [key, value] of predictedPatrolsByReferenceIdMap) {
     ids.push(key);
    }
    if(ids.length > 0){
      filter.referencePatrolIds = ids;
      filter.enable = true;
    }

    this.loadingOn();
    this.patrolService.loadPatrolRepetitionFromRestApi(filter).pipe(first()).subscribe((patrolRepetitions: PatrolRepetitionModel[]) => {
      
      if(patrolRepetitions.length>0){
        const patrolRepetitionsToUpdate: PatrolRepetitionModel[]=[];
        for (let [key, predictedPatrols] of predictedPatrolsByReferenceIdMap) {

          let patrolRepetition = patrolRepetitions.find((p)=> {return p.referencePatrolId === key})
          if(!patrolRepetition) return;

          predictedPatrols.forEach(patrol => {
            let excludedDate: number = DateUtils.getStartOfDayUTC(patrol.startDate);
            if(!patrolRepetition.excludedRepetitions) patrolRepetition.excludedRepetitions = [];
            patrolRepetition.excludedRepetitions.push(excludedDate.toString());
          });
          
          patrolRepetitionsToUpdate.push(patrolRepetition);
        }

        this.updatePatrolRepetitions(patrolRepetitionsToUpdate);
      }else {
        this.toastr.warning("Não existem dados das rondas repetidas selecionadas");
      }
    },
    (error) => {
      this.logger.error('Erro ao carregar as rondas repetidas' + error);
    },
    () => {
      this.loadingOff();
    });
  }

  updatePatrolRepetitions(patrolRepetitions: PatrolRepetitionModel[]){
    this.logger.debug('PatrolListComponet.updatePatrolRepetitions()');
    this.patrolService.updatePatrolRepetitions( patrolRepetitions).pipe(first()).subscribe((patrolRepetition: PatrolRepetitionModel[]) => {
      this.toastr.success("Ronda(s) removida(s) com sucesso", "Remover");
      this.loadRecordsFromServer();
    }, error => {
      this.logger.error(error);
      this.toastr.error('Não foi possível remover as rondas repetidas');
    },() => {
    });
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'author': return this.lowerAndTrimText(this.getUserNameLoginTitle(item['author']));
      default: return super.getPropertyByPath(item, property);
    }
  }

  checkPermissions(){
    this.isHiddenButtonCreateEditPatrol = !this.authorizationService.userHasPermission(this.permission.CREATE_EDIT_PATROL);
    this.isHiddenButtonDeletePatrol = !this.authorizationService.userHasPermission(this.permission.DELETE_PATROL);
  }
}
