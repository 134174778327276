<form class=" edit-form h-100">
    <div class="sipd-container">

      <div class="sipd-header">
        <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVO]':'')}}</span>
        <div class="fill-space"></div>
        <div class="actions">
          <button mat-raised-button 
            matTooltip="Habilitar Edição"
            [hidden]="!canEdit() || !readOnly"
            (click)="onEnableEditClick()">
            Habilitar Edição
          </button>
          <button mat-icon-button color="primary"
            matTooltip="Copiar"
            (click)="onCopyClick()"
            [disabled]="!id"
            [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP)'>
            <mat-icon class="material-icons-outlined">file_copy</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="onDeleteClick()"
            matTooltip="Remover"
            [hidden]='!authorizationService.userHasPermission(permission.DELETE_REGISTRATION_GROUP)'>
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
          <button mat-icon-button  (click)="onLocationClick()" matTooltip="Mostrar no Mapa" [disabled]="!hasLocation()">
            <mat-icon class="material-icons-outlined">location_on</mat-icon>
          </button>
          <button mat-icon-button  [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP)'>
            <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
          </button>
          <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
            <button mat-icon-button  (click)="onSaveClick($event)" [disabled]="!canSave()" matTooltip="Salvar" [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP)'>
              <mat-icon class="material-icons-outlined">save</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="sipd-content bg-white">
        <div class="p-3">
          <h5 class="user-edit-label">Nome</h5>
          <mat-divider></mat-divider>

          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Nome</mat-label>
              <input [readonly]="readOnly" cdkFocusInitial matInput name="name" placeholder="Nome" [(ngModel)]="view['name']"
                oninput="this.value = this.value.trimLeft()" required>
              <mat-error>Campo Obrigatório</mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div *ngIf="hasGeometry" class="p-3">
          <h5 class="user-edit-label">UF(s)</h5>
          <mat-divider></mat-divider>
  
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <multiselect-autocomplete [data]="singleDataCacheService.getValues('State')" placeholder="UF(s)"
                  [(valueData)]="view['states']" [disabled]="readOnly" fxFlex>
            </multiselect-autocomplete>
          </div>
        </div>

        <div *ngIf="hasNumber" class="p-3">
          <h5 class="user-edit-label">Valor</h5>
          <mat-divider></mat-divider>

          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <mat-form-field *ngIf="hasNumber" appearance="outline" fxFlex>
              <mat-label>Valor</mat-label>
              <input [readonly]="readOnly" type="number" cdkFocusInitial matInput name="valor" placeholder="valor" [(ngModel)]="view['opsNumber']"
                oninput="this.value = this.value.trimLeft()" required>
              <mat-error>Campo Obrigatório</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="dataType == 'Dc'" class="p-3">
          <h5 class="user-edit-label">Ano</h5>
          <mat-divider></mat-divider>

          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <mat-form-field *ngIf="dataType=='Dc'" appearance="outline" fxFlex>
              <mat-label>Ano</mat-label>
              <input [readonly]="readOnly" matInput mask="0000" [dropSpecialCharacters]="false" name="year" placeholder="Ano"
                matTooltip="{{ view['year'] }}" [(ngModel)]="view['year']" required>
            </mat-form-field> 
          </div>
        </div>

    </div>

    </div>
    
  </form>
