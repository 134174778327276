import {LocationModel} from './location.model';
import { InspectionStatusType, PointType } from './enums.enum';
import { InspectionFormModel } from './inspection.form.model';

export class InspectionModel {
  identifier:       string;            // Id gerado manualmente quando a operação é salva (criada ou atualizada)
  status:           string;
  name:             string;            // The full name of inspection point, i.e. band name, km, meters (e.g. FAIXA ORBEL KM 004+950).
  location:         LocationModel;     // The point for the inspection
  activities:       Array<string>;     // The activities of the Inspection
  type:             string;            // CRITICAL_POINT ou BASE (é o que identifica uma base)

  inspectionPointId: string;           // Preenchido se o ponto foi importado do cadastro de pontos (InspectionPointModel.identifier)
  inspectionPointNoAccess: boolean;

  band:             string;
  km:               string;
  city:             string;
  state:            string;
  
  number:           string;            // Só é usado na web, para salvar o número do ponto sem acesso

  inspectionForms:  InspectionFormModel[];  // lista de formulários publicados, inicia vazia

  id_layer:         number;            // temporary id layer use only with markers and in the web

  constructor(){
    this.location = new LocationModel();
    this.status = InspectionStatusType.NOT_STARTED;
    this.type = PointType.CRITICAL_POINT;
    this.inspectionPointNoAccess = false;
  }
}
