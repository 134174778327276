import { AlertTypeDescription, AlertPriorityDescription, SourceType, SimfAlertTypeDescription, Permission, PermisionRuleOption, UserTypeDescription } from '../../../model/enums.enum';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { CentralFilterModel } from './central-filter.model';
import * as moment from 'moment';
import DateUtils from 'src/app/service/util/date-utils';
import { KeyValue } from '@angular/common';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { PermissionOptions } from 'src/app/model/field.model';
import { StorageService } from '../../../service/storage-service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';

export class TypeAlerts {
  name : string;
  value : string;
};

@Component({
  selector: 'app-central-filter-dialog',
  templateUrl: './central-filter-dialog.component.html',
  styleUrls: ['./central-filter-dialog.component.scss']
})

export class CentralFilterDialogComponent implements OnInit, OnDestroy {

  sourceApp: boolean;

  viewFilterStartDate : string;
  viewFilterEndDate :   string;
  viewFilterStartTime : string;
  viewFilterEndTime :   string;

  startDate: moment.Moment;
  endDate: moment.Moment;

  /** ENUMS */
  alertPriorityDescription = AlertPriorityDescription;
  alertTypeDescription     = AlertTypeDescription;
  simfAlertTypeDescription = SimfAlertTypeDescription;
  permission               = Permission;
  permisionRuleOption      = PermisionRuleOption;
  userTypeDescription      = UserTypeDescription;

  alertTypeDescriptionFiltered : TypeAlerts[] = [];
  
  loadingListService = new LoadingListService();

  constructor(private logger:                       NGXLogger,
              public dialogRef:                     MatDialogRef<CentralFilterDialogComponent>,
              public entityCacheService:            EntityCacheService,
              @Inject(MAT_DIALOG_DATA) public inMemoryFilterModel: CentralFilterModel,
              public filterModel:                   CentralFilterModel,
              public singleDataCacheService:  SingleDataCacheService,
              protected authorizationService:       AuthorizationService,
              protected storageService:             StorageService) {
    this.viewFilterStartTime = '';
    this.viewFilterEndTime = '';

    if (this.inMemoryFilterModel.sourceTypes[0] == SourceType.MOBILE_APP) {
      this.sourceApp = true;
    }
    else{
      this.sourceApp = false;
    }
  }

  ngOnInit() {
    this.loadFormOptionsData();
    this.filterModel = new CentralFilterModel();
    this.updateFilterModel(this.inMemoryFilterModel, this.filterModel);

    this.handleFilterDates();
    this.filterAlertTypeByProfile();
  }

  ngOnDestroy(){
    this.loadingListService.destroy();
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  startDateChanged() {
    this.viewFilterStartDate = this.startDate.format("DD/MM/yyyy");
  }

  applyStartDate(dateString: string) {
    this.startDate = moment(dateString, "DD/MM/yyyy");
  }

  endDateChanged() {
    this.viewFilterEndDate = this.endDate.format("DD/MM/yyyy");
  }

  applyEndDate(dateString: string) {
    this.endDate = moment(dateString, "DD/MM/yyyy");
  }

  private loadFormOptionsData() {
    this.logger.debug('CentralFilterDialogComponent.loadFormOptionsData()');
    this.loadPatrolTeams();
    this.loadVehicles();
    const metadatas: string[] = [
      "OpticalProcessingSystem"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  private loadPatrolTeams () {
    this.entityCacheService.loadPatrolTeams(this.loadingListService);
  }

  private loadVehicles () {
    this.entityCacheService.loadVehicles(this.loadingListService);
  }

  onFilter(){
    if (this.filterModel.current) {
      this.filterModel.startDate = DateUtils.getTodayStartDate();
      this.filterModel.endDate = DateUtils.getTodayEndDate();
    }
    else {
      this.filterModel.startDate = DateUtils.stringDateTimeToTimestamp(this.viewFilterStartDate, this.viewFilterStartTime, true);
      this.filterModel.endDate = DateUtils.stringDateTimeToTimestamp(this.viewFilterEndDate, this.viewFilterEndTime, false);
    }
    
    if(this.filterModel.startScalePoint == "")
      this.filterModel.startScalePoint = undefined;
    
    if(this.filterModel.endScalePoint == "")
      this.filterModel.endScalePoint = undefined;

    this.filterModel.placements = this.storageService.getSpecificPlacementIds();

    this.updateFilterModel(this.filterModel, this.inMemoryFilterModel);

    this.dialogRef.close(true);
  }

  updateFilterModel(sourceFilter: CentralFilterModel, targetFilter: CentralFilterModel){
    for(const key in sourceFilter){
      targetFilter[key] = sourceFilter[key];
    }
  }

  clearFilter(){
    // Não reseta o current para evitar que o diálogo mude de tamanho ao Limpar o filtro
    //this.filterModel.current = true;
    this.filterModel.startDate   = DateUtils.getTodayStartDate();
    this.filterModel.endDate     = DateUtils.getTodayEndDate();
    this.filterModel.patrolTeams = undefined;
    this.filterModel.vehicles    = undefined;
    this.filterModel.types       = undefined;
    this.filterModel.priorities  = undefined;
    this.filterModel.simfAlertTypes = undefined;
    this.filterModel.cpsNumber = undefined;
    this.filterModel.startChannel = undefined;
    this.filterModel.endChannel = undefined;
    this.filterModel.startScalePoint = undefined; 
    this.filterModel.endScalePoint= undefined;

    this.handleFilterDates();
  }

  handleFilterDates(){
      this.startDate = moment(this.filterModel.startDate);
      this.viewFilterStartDate = this.startDate.format('DD/MM/yyyy');
      this.viewFilterStartTime = this.startDate.format('HH:mm');

      this.endDate = moment(this.filterModel.endDate);
      this.viewFilterEndDate = this.endDate.format('DD/MM/yyyy');
      this.viewFilterEndTime = this.endDate.format('HH:mm');
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  /**Filtra a lista de origem de acordo com o perfil do usuário */
  filterAlertTypeByProfile(){
    const typeAlerts = Object.keys(this.alertTypeDescription).map((name) => {
      return {
        name,
        value: this.alertTypeDescription[name as keyof typeof AlertTypeDescription],
      };
    });

    let permission_option: PermissionOptions = this.authorizationService.getUserPermissionOptions(this.permission.LIST_ALERTS);

    typeAlerts.forEach(element => {
      if(permission_option && permission_option.option === this.permisionRuleOption.SOME_FIELDS && permission_option.fields.find(x=>x === element.name)) {
        this.alertTypeDescriptionFiltered.push(element);
      }
      else if(permission_option && (permission_option.option === this.permisionRuleOption.ALL_FIELD || !permission_option.option)) {
        this.alertTypeDescriptionFiltered.push(element);
      }
  });
  }
}
