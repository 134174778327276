<div class="sipd-container">

  <header class="sipd-header  sipd-header-small">
    <button mat-icon-button color="accent" matTooltip="Filtrar Alertas" (click)="openFilterDialog()">
      <mat-icon class="material-icons-outlined">filter_list</mat-icon>
    </button>
    <span class="central-header-title">{{title}}</span><small matTooltip="Número de Registros Filtrados" >[ {{getListCount()}} ]</small>
  </header>
  <mat-divider></mat-divider>
  <div class="sipd-content bg-white">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent" [checked]="selection.hasValue()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="level">
        <th class="nowrap" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let alert">
          <div class="alert-level" [class]="alert?.alertPriority"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="identifier">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let alert">{{alert?.identifier}}</td>
      </ng-container>

      <ng-container matColumnDef="acknowledged">
        <th mat-header-cell class="fit-content nowrap " *matHeaderCellDef mat-sort-header>
          Recon.
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let element">
          <mat-icon *ngIf="element?.acknowledged === true" class="recon-check" [ngClass]='txt-warn'>
            done</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <th class="nowrap " mat-header-cell *matHeaderCellDef mat-sort-header> Data/Hora </th>
        <td mat-cell class="nowrap " *matCellDef="let alert">{{ alert?.timestamp | date:'dd/MM/yyyy HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="observedArea">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Área Obs. </th>
        <td mat-cell class="ellipsis observed-area" style="max-width: 200px;" *matCellDef="let alert" [matTooltip]="alert?.observedArea">{{ alert?.observedArea }}</td>
      </ng-container>

      <ng-container matColumnDef="alertType">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Origem </th>
        <td mat-cell class="nowrap" *matCellDef="let alert">{{sourceApp? alertTypeDescription[alert?.alertType]: (simfAlertTypeDescription[alert?.simfAlertType]? simfAlertTypeDescription[alert?.simfAlertType]: alert?.simfAlertType)}}</td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Duração </th>
        <td mat-cell class="nowrap" *matCellDef="let alert">{{ getDuration(alert) }}</td>
      </ng-container>

      <ng-container matColumnDef="band">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Faixa </th>
        <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let alert" [matTooltip]="alert?.band">{{alert?.band}}</td>
      </ng-container>

      <ng-container matColumnDef="duct">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Duto </th>
        <td mat-cell *matCellDef="let alert">{{alert?.duct}}</td>
      </ng-container>

      <ng-container matColumnDef="km">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> KM </th>
        <td mat-cell *matCellDef="let alert">{{alert?.km}}</td>
      </ng-container>

      <ng-container matColumnDef="patrolTeam.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Equipe </th>
        <td mat-cell class="nowrap"*matCellDef="let alert">{{alert?.patrolTeam?.name}}</td>
      </ng-container>

      <ng-container matColumnDef="patrolTeam.company.placement.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Lotação da Empresa da Equipe"> Lotação </th>
        <td mat-cell class="nowrap" *matCellDef="let alert">{{alert?.patrolTeam?.company?.placement?.name}}</td>
      </ng-container>

      <ng-container matColumnDef="patrolTeam.serviceType">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Serviço </th>
        <td mat-cell *matCellDef="let alert">{{ serviceTypeDescription[alert?.patrolTeam?.serviceType] }}</td>
      </ng-container>

      <ng-container matColumnDef="simf.endChannel">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
        <td mat-cell *matCellDef="let alert">{{ alert.simf?.endChannel }}</td>
      </ng-container>

      <ng-container matColumnDef="simf.cpsNumber">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> OPS </th>
        <td mat-cell *matCellDef="let alert">{{ alert.simf?.cpsNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="simf.scalePoint">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Kpoint </th>
        <td mat-cell *matCellDef="let alert">{{ alert.simf?.scalePoint }}</td>
      </ng-container>

      <ng-container matColumnDef="simf.zoneName">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
        <td mat-cell *matCellDef="let alert">{{ alert.simf?.zoneName }}</td>
      </ng-container>

      <ng-container matColumnDef="observation">
        <th class="ellipsis" style="max-width: 300px; min-width: 300px;" mat-header-cell *matHeaderCellDef mat-sort-header> Notas </th>
        <td mat-cell class="ellipsis" style="max-width: 300px; min-width: 300px;" *matCellDef="let alert" [matTooltip]="alert?.observation">{{alert?.observation}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row  *matRowDef="let row; columns: displayedColumns; let element" (click)="onAlertClick(row)"
        [ngClass]="{ 'highlighted': selection.isSelected(row), 'actived': isAlertInDetails(row) }"
        (contextmenu)="openContextMenu($event, element)"
        tabindex="999"
        (keydown.arrowdown)="arrowDownEvent()"
        (keydown.arrowup)="arrowUpEvent()"
        >
      </tr>

    </table>
  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" *ngIf="sourceApp" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
  <app-global-spinner class="sipd-spinner" style="top:50%" *ngIf="!sourceApp" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>

<mat-menu #contextMenu="matMenu">
  <button mat-menu-item (click)="onLocationClick(this.contextMenuSelectedItem)"
    [disabled]="!hasLocation(this.contextMenuSelectedItem)">
    <mat-icon class="material-icons-outlined">location_on</mat-icon>
    <span>Mostrar no Mapa</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="onCreateEventClick(this.contextMenuSelectedItem)" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_EVENT)'>
    <mat-icon class="material-icons-outlined">add_box</mat-icon>
    <span>Criar Evento e Vincular</span>
  </button>
  <button mat-menu-item (click)="onLinkEventClick(this.contextMenuSelectedItem)" [hidden]='!authorizationService.userHasPermission(permission.EDIT_ALERT)'>
    <mat-icon class="material-icons-outlined">add_link</mat-icon>
    <span>Vincular Evento...</span>
  </button>
  <button mat-menu-item (click)="onUnLinkEventClick(this.contextMenuSelectedItem)" [disabled]="!canUnLinkEvent(this.contextMenuSelectedItem)" [hidden]='!authorizationService.userHasPermission(permission.EDIT_ALERT)'>
    <mat-icon class="material-icons-outlined">link_off</mat-icon>
    <span>Desvincular Evento</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="onAcknowledgeClick(this.contextMenuSelectedItem)" [disabled]="!canAcknowledge(this.contextMenuSelectedItem)" [hidden]='!authorizationService.userHasPermission(permission.EDIT_ALERT)'>
    <mat-icon class="material-icons-outlined">done</mat-icon>
    <span>Reconhecer Alerta</span>
  </button>
  <button mat-menu-item (click)="onOperationClick(this.contextMenuSelectedItem)" [disabled]="!hasOperation(this.contextMenuSelectedItem)">
    <mat-icon class="material-icons-outlined">folder_open</mat-icon><span>Abrir Operação</span>
  </button>
  <button mat-menu-item (click)="onMessagesClick(this.contextMenuSelectedItem)" [disabled]="!hasOperation(this.contextMenuSelectedItem)">
    <mat-icon class="material-icons-outlined">forum</mat-icon><span>Mensagens da Operação</span>
  </button>
</mat-menu>
