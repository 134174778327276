import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environmentLoader as environmentLoaderPromise } from './environments/environment-loader';

environmentLoaderPromise.then(env => {
  // Obrigatórias
  environment.production = env.production;
  environment.development = env.development;
  environment.hmg = env.hmg;
  environment.settings = env.settings;

  // Opcionais
  if (env.showPopupLayerTables) {
    environment.showPopupLayerTables = env.showPopupLayerTables;
  }
  if (env.useServerPagination) {
    environment.useServerPagination = env.useServerPagination;
  }
  if (env.allUsersCanUseApp) {
    environment.allUsersCanUseApp = env.allUsersCanUseApp;
  }
  if (env.defaultPageSize) {
    environment.defaultPageSize = env.defaultPageSize;
  }
  if (env.profileThresHold) {
    environment.profileThresHold = env.profileThresHold;
  }
  if (env.profileClassToConsoleEnabled) {
    environment.profileClassToConsoleEnabled = env.profileClassToConsoleEnabled;
  }
  
  // Inicialização
  if (environment.production) {
    console.log("Modo de Produção Habilitado.");
    enableProdMode();
  }
  else {
    console.log("Modo de Produção Desabilitado.");
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
