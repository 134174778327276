<mat-form-field appearance="outline">
	<mat-label>{{label ? label : placeholder}}</mat-label>
	<input [placeholder]="placeholder" matInput [matAutocomplete]="auto" [formControl]="dataControl" [readonly]="_readonly"
		[required]="_required" [disabled]="true"  (focusin)="focusInFunction()" #someInput>
	<mat-error>Campo Obrigatório</mat-error>
	<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (opened)="onOpenedEvent($event)" (closed)="onClosedEvent($event)">
	<mat-option *ngFor="let data of filteredData | async" [value]="selectedData">
		<mat-checkbox [checked]="isSelected(data)" (change)="toggleSelection(data)" (click)="$event.stopPropagation()">
			{{ displayData(data) }}
		</mat-checkbox>
	</mat-option>
	</mat-autocomplete>
</mat-form-field>