import { AlertModel } from './../../model/alert.model';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EntityService } from './entity.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CentralFilterModel } from '../../pages/central/central-filter-dialog/central-filter.model';
import { Observable } from 'rxjs';
import { API_VERSION_ENDPOINT } from '../../common/constants';
import FieldUtils from '../util/field-utils';

@Injectable({
  providedIn: 'root'
})
export class AlertsService extends EntityService {

  private defaultFilter : CentralFilterModel;

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.alerts_address}/alerts`);
    this.defaultFilter = new CentralFilterModel();
  }

  public loadListFromRestApi(): Observable<AlertModel[]> {
    const observable: Observable<AlertModel[]> = super.loadFromRestApi<AlertModel[]>();
    return this.processAlertLevels(observable);
  }

  extractAttributeList(newList: Object[], attributeName: string) {
    let listOfObjects: Object[] = newList;

    if (!listOfObjects || !Array.isArray(listOfObjects) || !attributeName) {
      throw new Error('Invalid arguments provided.');
    }

    const attributeValues = listOfObjects.map(object => object[attributeName]);
  
    return attributeValues;
  }

  public loadFromServerBySearch(filter: CentralFilterModel): Observable<AlertModel[]> {
    let options : Object = 
    undefined;
    if (filter) {
      if(filter.startScalePoint) 
        filter.startScalePoint = FieldUtils.kmStringToSimf(filter.startScalePoint);
      if(filter.endScalePoint) 
        filter.endScalePoint = FieldUtils.kmStringToSimf(filter.endScalePoint);


      //Filtro utilizado para carregar apenas o valor do objeto OPS
      let specifiedFilter: CentralFilterModel = JSON.parse(JSON.stringify(filter));
      
      if(filter.cpsNumber){
        specifiedFilter.cpsNumber = this.extractAttributeList(specifiedFilter.cpsNumber, 'opsNumber')
      }

      options = {
        params: new HttpParams().set('filter', JSON.stringify(specifiedFilter)),
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }) };

    } else {
      // TODO deixar esse options ja pronto...
      options = {
        params: new HttpParams().set('filter', JSON.stringify(this.defaultFilter)),
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
    }

    const observable: Observable<AlertModel[]> = this.http.get<AlertModel[]>(this.apiUrl+'/search', options);

    if (filter) {
      if(filter.startScalePoint) 
        filter.startScalePoint = FieldUtils.kmSimfToString(filter.startScalePoint);
      if(filter.endScalePoint) 
        filter.endScalePoint = FieldUtils.kmSimfToString(filter.endScalePoint);
    }

    return this.processAlertLevels(observable);
  }

  /**
   * Obtem a versão do projeto Alerts
   */
  public getServiceVersion(): Observable<any> {
    return this.http.get(`${this.apiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }

  private processAlertLevels(result : Observable<AlertModel[]>): Observable<AlertModel[]> {
    return result.pipe(map((alerts: AlertModel[]) => {
      alerts.forEach((alert: AlertModel) => {
        if (alert.alertPriority == 'MAX') { // backward compatibility - não existe mais
          alert.alertPriority = 'HIGH';
        }
      });

      return alerts;
    }));
  }
}
