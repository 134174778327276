<div class="sipd-container">
  <div class="sipd-header">

    <button mat-icon-button color="accent" (click)="sidenav.toggle(); sidenavToggle(sidenav.opened)">
      <mat-icon *ngIf="!sidenav.opened">filter_list</mat-icon>
      <mat-icon *ngIf="sidenav.opened">arrow_left</mat-icon>
    </button>

    <div _ngcontent-uqp-c230="" class="mat-title m-0"> {{title}}
      <span *ngIf="editionMode"> [Modo de Edição] - <span
          class="mat-body-2">{{operationTypeDescription[editionMode.operation.type] + ' ' +
          editionMode.operation.identifier}}</span>
        <button mat-icon-button (click)="onCloseEditionMode()"
                matTooltip="Fechar Modo de Edição" >
          <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
        </button>
      </span>
    </div>
    <div class="fill-space"></div>
    <div class="action">
      <mat-form-field appearance="outline" class="header-input">
        <input matInput type="text" [mask]="latLongMask" [patterns]="latLongPattern" [dropSpecialCharacters]="false"
          [validation]="false" [(ngModel)]="locationMarkerLatLong" placeholder="Lat, Long"
          matTooltip="Pressione Enter para Adicionar Marcador de Posição"
          (keyup.enter)="onAddLocationMarker()" (paste)="onPasteLatLong($event)">
        <button mat-icon-button matSuffix (click)="onRemoveLocationMarker()"
                matTooltip="Remove Marcador de Posição">
          <mat-icon class="material-icons-outlined">close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-icon-button (click)="onZoomFitClick()"
              matTooltip="Ajusta o Zoom para visualização padrão">
        <mat-icon class="material-icons-outlined">zoom_out_map</mat-icon>
      </button>
    </div>
  </div>

  <div class="sipd-content">

    <mat-sidenav-container class="h-100">
      <mat-sidenav #sidenav mode="side" closed style="width: 350px;">

        <div class="sipd-container">
          <div class="sipd-header">
              <!--Panel de Busca-->
              <mat-form-field class="search-form header-input" appearance="outline">
                <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
                <input matInput type="search" [(ngModel)]="searchValue" (keyup)="keyupSearch()" 
                  placeholder="Buscar..." matTooltip="Busca o texto no painel lateral.
                                                      Resultados filtram os itens do painel apenas, não afetam o mapa.">
                <button mat-icon-button matSuffix (click)="onRemoveSearch()">
                  <mat-icon class="material-icons-outlined icon-sm">close</mat-icon>
                </button>
              </mat-form-field>
          </div>

          <div class="sipd-content">

            <mat-accordion multi displayMode="flat" class="map-panel m-0 p-0">

              <mat-divider style="border-top-color: rgba(0, 0, 0, 0.10);"></mat-divider>

              <!--Panel de Camadas-->
              <mat-expansion-panel [disabled]="layersMap.size==0" *ngIf="!searchValue || layersSearchCount > 0" [expanded]="layersExpanded" (opened)="layersExpanded=true" (closed)="layersExpanded=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">layers</mat-icon>
                    <span class="mat-body-2 ml-1">Camadas</span>
                    <div class="ml-auto" *ngIf="layersMap.size!=0">
                      <button mat-icon-button color="accent" (click)="onLayersVisibilityClick(); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todas as Camadas">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="layersVisible">visibility</span>
                          <span *ngIf="!layersVisible">visibility_off</span>
                        </mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="layersMap.size!=0">

                  <!--Panel de Filtro de Camadas-->
                  <mat-expansion-panel *ngIf="!searchValue">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="d-flex align-items-center">
                        <mat-icon class="material-icons-outlined">filter_list</mat-icon>
                        <span class="mat-body-2 ml-1">Filtro</span>
                        <div class="ml-auto">
                          <button mat-icon-button color="accent" (click)="onResetLayersFilterClick(); $event.stopPropagation();"
                                  matTooltip="Remove todos os filtros de camadas">
                            <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                          </button>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                      <!--Anos-->
                      <mat-expansion-panel matTooltip="Filtra por anos.
                                                       (Afeta 'Histórico de DCs' apenas)"
                        class="m-0 p-0  mat-elevation-z0" [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_DC_HISTORY_ID)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>Anos (p/ DCs): </span>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1">({{layersFilter?.years.length}}/{{allDcYears?.length}})</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="selectLayerFilter(filterOption.YEAR, true); $event.stopPropagation();"
                                    matTooltip="Remove filtro" class="small-button" [disabled]="layersFilter?.years.length==0">
                                    <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" class="pt-0 mat-list-scroll">
                          <mat-list-item role="listitem" *ngFor="let year of allDcYears" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="onLayerFilterListClick($event.checked, year, layersFilter.years, filterOption.YEAR)"
                                  [checked]="isLayerFilterListSelected(year, layersFilter.years)">
                                  {{year}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>

                      </mat-expansion-panel>

                      <!--Estados-->
                      <mat-expansion-panel matTooltip="Filtra por Estados. Afeta todas as camadas.
                                                       (Mas algumas camadas podem estar em mais de um estado)"
                        class="m-0 p-0  mat-elevation-z0" hideToggle="false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>Estados:</span>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1">({{layersFilter.states.length}}/{{singleDataCacheService.getValues('State').length}})</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="selectLayerFilter(filterOption.STATE, true); $event.stopPropagation();"
                                    matTooltip="Remove filtro" class="small-button" [disabled]="layersFilter.states.length==0">
                                    <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" class="pt-0 mat-list-scroll">
                          <mat-list-item role="listitem" *ngFor="let state of singleDataCacheService.getValues('State')" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="onLayerFilterListClick($event.checked, state, layersFilter.states, filterOption.STATE)"
                                  [checked]="isLayerFilterListSelected(state, layersFilter.states)">
                                  {{state}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>

                      </mat-expansion-panel>

                      <!--Faixas-->
                      <mat-expansion-panel matTooltip="Filtra por Faixas
                                                       (Afeta Marcos Quilométricos, Simf, Pontos de Entrega, Estações, Válvulas e Áreas Observadas)"
                        class="m-0 p-0  mat-elevation-z0">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>Faixas:</span>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1"> ({{layersFilter.bandIds?.length}}/{{bandsFiltered.length}})</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="selectLayerFilter(filterOption.BAND, true); $event.stopPropagation();"
                                    matTooltip="Remove filtro" class="small-button" [disabled]="!layersFilter.bandIds">
                                    <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" class="pt-0 mat-list-scroll" [disabled]="!bandsFiltered || bandsFiltered.length == 0">
                          <mat-list-item role="listitem" *ngFor="let band of bandsFiltered" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="onLayerFilterListClick($event.checked, band.name, layersFilter.bandNames, filterOption.BAND)" [matTooltip]="band.name"
                                  [checked]="isLayerFilterListSelected(band.name, layersFilter.bandNames)">
                                  {{band.name}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>

                      </mat-expansion-panel>

                      <!--Status Áreas Observadas-->
                      <mat-expansion-panel matTooltip="Filtra Áreas Observadas por Status" 
                        class="m-0 p-0  mat-elevation-z0" [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_OBSERVED_AREA_ID)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>Status (p/ A.Obs.): </span>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1">{{ layersFilter?.areaStatus === filterOptionALL ? 'Todos' : areaStatusDescriptions[layersFilter?.areaStatus] }}</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="selectLayerFilter(filterOption.AREA_STATUS, true); $event.stopPropagation();"
                                    matTooltip="Remove filtro" class="small-button">
                                    <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" class="pt-0">
                          <mat-list-item role="listitem" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="layersFilter.areaStatus = filterOptionALL; selectLayerFilter(filterOption.AREA_STATUS, false)"
                                  [checked]="layersFilter.areaStatus === filterOptionALL">
                                  Todos
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                          <mat-list-item role="listitem" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="layersFilter.areaStatus = areaStatus.ACTIVE; selectLayerFilter(filterOption.AREA_STATUS, false)"
                                  [checked]="layersFilter.areaStatus === areaStatus.ACTIVE">
                                  {{areaStatusDescriptions[areaStatus.ACTIVE]}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                          <mat-list-item role="listitem" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="layersFilter.areaStatus = areaStatus.INACTIVE; selectLayerFilter(filterOption.AREA_STATUS, false)"
                                  [checked]="layersFilter.areaStatus === areaStatus.INACTIVE">
                                  {{areaStatusDescriptions[areaStatus.INACTIVE]}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>

                      </mat-expansion-panel>

                      <!-- Responsável (Áreas Observadas)-->
                      <mat-expansion-panel matTooltip="Filtra Áreas Observadas por Responsável" 
                        class="m-0 p-0  mat-elevation-z0" [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_OBSERVED_AREA_ID)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>Responsável (p/ A.Obs.):</span>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1"> ({{ layersFilter.responsiblesIds?.length }}/{{ allResponsibles?.length }})</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="selectLayerFilter(filterOption.AREA_RESPONSIBLE, true); $event.stopPropagation();"
                                    matTooltip="Remove filtro" class="small-button" [disabled]="!layersFilter.responsiblesIds">
                                    <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" class="pt-0 mat-list-scroll" [disabled]="!allResponsibles || allResponsibles.length == 0">
                          <mat-list-item role="listitem" *ngFor="let responsible of allResponsibles" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="onLayerFilterListClick($event.checked, responsible.id, layersFilter.responsiblesIds, filterOption.AREA_RESPONSIBLE)" [matTooltip]="responsible.name"
                                  [checked]="isLayerFilterListSelected(responsible.id, layersFilter.responsiblesIds)">
                                  {{responsible.name}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>

                      </mat-expansion-panel>

                      <!-- Áreas Observadas -->
                      <mat-expansion-panel matTooltip="Filtra as Áreas Observadas" 
                        class="m-0 p-0  mat-elevation-z0" [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_OBSERVED_AREA_ID)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>Áreas Observadas:</span>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1"> ({{ layersFilter.areasIds?.length }}/{{ areasFiltered?.length }})</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="selectLayerFilter(filterOption.AREA_ID, true); $event.stopPropagation();"
                                    matTooltip="Remove filtro" class="small-button" [disabled]="!layersFilter.areasIds">
                                    <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" class="pt-0 mat-list-scroll" [disabled]="!areasFiltered || areasFiltered.length == 0">
                          <mat-list-item role="listitem" *ngFor="let area of areasFiltered" style="cursor: pointer;">
                            <div class="d-flex w-100 align-items-center bg-shade-100">
                              <div class="mat-body-1 ml-3">
                                <mat-checkbox color="accent" class="mr-2" (click)="$event.stopPropagation()" color="accent"
                                  (change)="onLayerFilterListClick($event.checked, area.id, layersFilter.areasIds, filterOption.AREA_ID)" [matTooltip]="area.name"
                                  [checked]="isLayerFilterListSelected(area.id, layersFilter.areasIds)">
                                  {{area.name}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </mat-list-item>
                        </mat-list>

                      </mat-expansion-panel>

                  </mat-expansion-panel>

                  <mat-divider></mat-divider>

                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of layersMap | keyvalue:layerListOrder" style="cursor: pointer;">

                      <mat-expansion-panel class="mb-1" *ngIf="!searchValue || layersSearchMap.get(item.key)?.length > 0"
                           [expanded]="layersSearchExpanded[item.key]" (opened)="layersSearchExpanded[item.key]=true" (closed)="layersSearchExpanded[item.key]=false">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="d-flex align-items-center">
                            <img [src]="layerFilterIcon(item.key)" />
                            <div class="ml-2 mr-2"> {{item.key }} </div>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div class="ml-auto mr-1" *ngIf="searchValue">({{layersSearchMap.get(item.key)?.length}}/{{layerCount(item.key)}})</div>
                            <div class="ml-auto mr-1" *ngIf="!searchValue">({{layerCount(item.key)}})</div>
                          </mat-panel-description>
                          <div class="d-flex ml-auto">
                            <button mat-icon-button color="accent" (click)="onLayerVisibilityClick(item.value); $event.stopPropagation();"
                                    [matTooltip]="'Mostra/Esconde a Camada' + getLayerTooltip(item.key)" class="small-button">
                              <mat-icon class="material-icons-outlined icon-sm2">
                                <span *ngIf="onLayerIsVisibile(item.value)">visibility</span>
                                <span *ngIf="!onLayerIsVisibile(item.value)">visibility_off</span>
                              </mat-icon>
                            </button>
                          </div>
                        </mat-expansion-panel-header>

                        <!-- Resultados da Busca de Camadas -->
                        <div class="historic-track-bar p-1" *ngIf="layersSearchMap.get(item.key)?.length">
                          <mat-list role="list" class="pt-0 mat-list-scroll">
                            <mat-list-item role="listitem" *ngFor="let searchItem of layersSearchMap.get(item.key) | keyvalue:layerSearchListOrder" style="cursor: pointer;">
                              <div class="d-flex w-100 mb-2 align-items-center">
                                <div class="mat-body-1 ml-1" (click)="onLayerSearchClick(searchItem.value)">
                                  <span>{{searchItem.value.name}}</span>
                                </div>
                              </div>
                            </mat-list-item>
                          </mat-list>
                        </div>
      
                      </mat-expansion-panel>
  
                    </mat-list-item>
                  </mat-list>

                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Equipes-->
              <mat-expansion-panel [disabled]="!hasTeamTracking()" *ngIf="!searchValue || teamTrackingsListedCount > 0" [expanded]="teamTrackingsExpanded" (opened)="teamTrackingsExpanded=true" (closed)="teamTrackingsExpanded=false">
                <mat-expansion-panel-header [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_TEAM)">
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">people</mat-icon>
                    <span class="mat-body-2 ml-1">Equipes</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && hasTeamTracking()">({{teamTrackingsListedCount}}/{{teamTrackingsCount}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && hasTeamTracking()">({{teamTrackingsCount}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="hasTeamTracking()">
                      <button mat-icon-button color="accent" (click)="onTeamTrackingsVisibilityClick(); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todas as Equipes">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="teamTrackingsVisible">visibility</span>
                          <span *ngIf="!teamTrackingsVisible">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onTeamTrackingsRemoveClick(); $event.stopPropagation();"
                              matTooltip="Remove todas as Equipes">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="hasTeamTracking()">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of teamTrackingObjectsMap | keyvalue:teamTrackingListOrder" style="cursor: pointer;">
                      <div class="d-flex w-100 mb-0 align-items-center" *ngIf="item.value.listed">
                        <div class="ml-2 mr-2" (click)="onTeamTrackingClick(item.value)">
                          <span class="teamtitle">{{getTeamTrackingObjectTitle(item.value)}}</span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" matTooltip="Mostrar Rastro" (click)="onTeamTrackingHistoricalTrackingClick(item.value)" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">my_location</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onTeamTrackingVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde a Equipe" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onTeamTrackingRemove(item.value)"
                                  matTooltip="Remove a Equipe" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>

                      <!-- Lista de elementos da equipe (profissionais e veículos) -->
                      <mat-list role="list" class="pt-0" *ngIf="item.value.listed">
                        <mat-list-item role="listitem" *ngFor="let trackingItem of item.value.trackings | keyvalue:trackingItemListOrder" style="cursor: pointer;">
                          <div class="ml-4 mr-2" (click)="onTeamTrackingItemClick(trackingItem.value)">
                            <span class="reduced-font">{{getTrackingTitle(trackingItem.value)}}</span>
                          </div>
                        </mat-list-item>
                      </mat-list>
                  </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Profissionais-->
              <mat-expansion-panel [disabled]="!hasTracking('MOBILE_APP')" *ngIf="!searchValue || trackingsListedCount['MOBILE_APP'] > 0" [expanded]="trackingsExpanded['MOBILE_APP']" (opened)="trackingsExpanded['MOBILE_APP']=true" (closed)="trackingsExpanded['MOBILE_APP']=false">
                <mat-expansion-panel-header [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_TEAM)">
                  <mat-panel-title class="d-flex align-items-center" matTooltip="Profissionais Sem Equipe">
                    <mat-icon class="material-icons-outlined">person</mat-icon>
                    <span class="mat-body-2 ml-1">Profissionais</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && hasTracking('MOBILE_APP')">({{trackingsListedCount['MOBILE_APP']}}/{{trackingsCount['MOBILE_APP']}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && hasTracking('MOBILE_APP')">({{trackingsCount['MOBILE_APP']}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="hasTracking('MOBILE_APP')">
                      <button mat-icon-button color="accent" (click)="onTrackingsVisibilityClick('MOBILE_APP'); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todos os Profissionais">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="trackingsVisible['MOBILE_APP']">visibility</span>
                          <span *ngIf="!trackingsVisible['MOBILE_APP']">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onTrackingsRemoveClick('MOBILE_APP'); $event.stopPropagation();"
                              matTooltip="Remove todas os Profissionais">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="hasTracking('MOBILE_APP')">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of trackingObjectsMap | keyvalue:trackingListOrder" style="cursor: pointer;">
                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="isTrackingFromSource(item.value.tracking, 'MOBILE_APP') && item.value.listed">
                        <div class="ml-2 mr-2" (click)="onTrackingClick(item.value)">
                          <span class="reduced-font">{{getTrackingTitle(item.value.tracking)}}</span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" matTooltip="Mostrar Rastro" (click)="onTrackingHistoricalTrackingClick(item.value)" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">my_location</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onTrackingVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde o Profissional" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onTrackingRemove(item.value.tracking)"
                                  matTooltip="Remove o Profissional" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Veículos-->
              <mat-expansion-panel [disabled]="!hasTracking('VEHICLE')" *ngIf="!searchValue || trackingsListedCount['VEHICLE'] > 0" [expanded]="trackingsExpanded['VEHICLE']" (opened)="trackingsExpanded['VEHICLE']=true" (closed)="trackingsExpanded['VEHICLE']=false">
                <mat-expansion-panel-header [hidden]="!authorizationService.userHasPermission(permission.VIEW_MAP_TEAM)">
                  <mat-panel-title class="d-flex align-items-center" matTooltip="Veículos Sem Equipe">
                    <mat-icon class="material-icons-outlined">directions_car</mat-icon>
                    <span class="mat-body-2 ml-1">Veículos</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && hasTracking('VEHICLE')">({{trackingsListedCount['VEHICLE']}}/{{trackingsCount['VEHICLE']}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && hasTracking('VEHICLE')">({{trackingsCount['VEHICLE']}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="hasTracking('VEHICLE')">
                      <button mat-icon-button color="accent" (click)="onTrackingsVisibilityClick('VEHICLE'); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todos os Veículos">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="trackingsVisible['VEHICLE']">visibility</span>
                          <span *ngIf="!trackingsVisible['VEHICLE']">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onTrackingsRemoveClick('VEHICLE'); $event.stopPropagation();"
                              matTooltip="Remove todos os Veículos">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="hasTracking('VEHICLE')">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of trackingObjectsMap | keyvalue:trackingListOrder" style="cursor: pointer;">
                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="isTrackingFromSource(item.value.tracking, 'VEHICLE') && item.value.listed">
                        <div class="ml-2 mr-2" (click)="onTrackingClick(item.value)">
                          <span>{{getTrackingTitle(item.value.tracking)}}</span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" matTooltip="Mostrar Rastro" (click)="onTrackingHistoricalTrackingClick(item.value)" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">my_location</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onTrackingVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde o Veículo" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onTrackingRemove(item.value.tracking)"
                                  matTooltip="Remove o Veículo" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Eventos-->
              <mat-expansion-panel [disabled]="eventObjectsMap.size==0" *ngIf="!searchValue || eventsListedCount > 0" [expanded]="eventsExpanded" (opened)="eventsExpanded=true" (closed)="eventsExpanded=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">flash_on</mat-icon>
                    <span class="mat-body-2 ml-1">Eventos</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && eventObjectsMap.size!=0">({{eventsListedCount}}/{{eventObjectsMap.size}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && eventObjectsMap.size!=0">({{eventObjectsMap.size}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="eventObjectsMap.size!=0">
                      <button mat-icon-button color="accent" (click)="onEventsVisibilityClick(); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todos os Eventos">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="eventsVisible">visibility</span>
                          <span *ngIf="!eventsVisible">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onEventsRemoveClick(); $event.stopPropagation();"
                              matTooltip="Remove todos os eventos">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="eventObjectsMap.size!=0">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of eventObjectsMap | keyvalue:eventListOrder" style="cursor: pointer;">

                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="item.value.listed">
                        <div class="ml-2 mr-2" (click)="onEventClick(item.value)">
                          <span *ngIf="item.value.event.identifier"> {{ item.value.event.identifier }} - {{ eventStatusDescription[item.value.event.status] }}</span>
                          <span *ngIf="!item.value.event.identifier"> {{ item.value.event.date | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" (click)="onEventVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde o Evento" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onEventRemoveClick(item.value)"
                                  matTooltip="Remove o Evento" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Verificações-->
              <mat-expansion-panel [disabled]="!hasOperation('EVENT_VERIFICATION')" *ngIf="!searchValue || operationsListedCount['EVENT_VERIFICATION'] > 0" [expanded]="operationsExpanded['EVENT_VERIFICATION']" (opened)="operationsExpanded['EVENT_VERIFICATION']=true" (closed)="operationsExpanded['EVENT_VERIFICATION']=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">timeline</mat-icon>
                    <span class="mat-body-2 ml-1">Verificações</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && hasOperation('EVENT_VERIFICATION')">({{operationsListedCount['EVENT_VERIFICATION']}}/{{operationsCount['EVENT_VERIFICATION']}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && hasOperation('EVENT_VERIFICATION')">({{operationsCount['EVENT_VERIFICATION']}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="hasOperation('EVENT_VERIFICATION')">
                      <button mat-icon-button color="accent" (click)="onOperationsVisibilityClick('EVENT_VERIFICATION'); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todas as Verificações">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="operationsVisible['EVENT_VERIFICATION']">visibility</span>
                          <span *ngIf="!operationsVisible['EVENT_VERIFICATION']">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onOperationsRemoveClick('EVENT_VERIFICATION'); $event.stopPropagation();"
                              matTooltip="Remove todas as verificações">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="operationObjectsMap.size!=0">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of operationObjectsMap | keyvalue:operationListOrder" style="cursor: pointer;">

                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="item.value.operation.type == 'EVENT_VERIFICATION' && item.value.listed">
                        <div class="ml-2 mr-2" (click)="onOperationClick(item.value)">
                          <span> {{ getOperationTitle(item.value.operation) }} </span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" matTooltip="Mostrar Rastro" [disabled]="!canHasOperationHistoricalTracking(item.value)" 
                              (click)="onOperationHistoricalTrackingClick(item.value)" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">my_location</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onOperationVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde a Verificação" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onOperationRemoveClick(item.value)"
                                  matTooltip="Remove a Verificação" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Rondas-->
              <mat-expansion-panel [disabled]="!hasOperation('PATROL')" *ngIf="!searchValue || operationsListedCount['PATROL'] > 0" [expanded]="operationsExpanded['PATROL']" (opened)="operationsExpanded['PATROL']=true" (closed)="operationsExpanded['PATROL']=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">timeline</mat-icon>
                    <span class="mat-body-2 ml-1">Rondas</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && hasOperation('PATROL')">({{operationsListedCount['PATROL']}}/{{operationsCount['PATROL']}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && hasOperation('PATROL')">({{operationsCount['PATROL']}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="hasOperation('PATROL')">
                      <button mat-icon-button color="accent" (click)="onOperationsVisibilityClick('PATROL'); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todas as Rondas">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="operationsVisible['PATROL']">visibility</span>
                          <span *ngIf="!operationsVisible['PATROL']">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onOperationsRemoveClick('PATROL'); $event.stopPropagation();"
                              matTooltip="Remove todas as rondas">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="hasOperation('PATROL')">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of operationObjectsMap | keyvalue:operationListOrder" style="cursor: pointer;">

                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="item.value.operation.type == 'PATROL' && item.value.listed">
                        <div class="ml-2 mr-2" (click)="onOperationClick(item.value)">
                          <span> {{ getOperationTitle(item.value.operation) }} </span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" matTooltip="Mostrar Rastro" [disabled]="!canHasOperationHistoricalTracking(item.value)" 
                              (click)="onOperationHistoricalTrackingClick(item.value)" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">my_location</mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onOperationVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde a Ronda" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onOperationRemoveClick(item.value)"
                                  matTooltip="Remove a Ronda" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Rastros-->
              <mat-expansion-panel [disabled]="historicalTrackingMap.size==0" *ngIf="!searchValue || historicalTrackingsListedCount > 0" [expanded]="historicalTrackingsExpanded" (opened)="historicalTrackingsExpanded=true" (closed)="historicalTrackingsExpanded=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">show_chart</mat-icon>
                    <span class="mat-body-2 ml-1">Rastros</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && historicalTrackingMap.size!=0">({{historicalTrackingsListedCount}}/{{historicalTrackingMap.size}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && historicalTrackingMap.size!=0">({{historicalTrackingMap.size}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="historicalTrackingMap.size!=0">
                      <button mat-icon-button color="accent" (click)="onHistoricalTrackingsVisibilityClick(); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todos os Rastros">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="historicalTrackingsVisible">visibility</span>
                          <span *ngIf="!historicalTrackingsVisible">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onHistoricalTrackingsRemoveClick(); $event.stopPropagation();"
                              matTooltip="Remove todos os rastros">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="historicalTrackingMap.size!=0">
                  <div *ngFor="let item of historicalTrackingMap | keyvalue:historicalTrackingListOrder" style="cursor: pointer;">
                    <mat-expansion-panel class="mb-1" [disabled]="historicalTrackingMap.size==0" >
                      <mat-expansion-panel-header *ngIf="item.value.listed">
                        <mat-panel-title class="d-flex align-items-center">
                          <div class="mat-body-1" (click)="onHistoricalTrackingClick(item.value); $event.stopPropagation();">
                            <span>{{getHistoricalTrackingTitle(item.value.mobileObjectId, item.value.sourceType, item.value.patrolTeam, item.value.operation, item.value.user)}}</span>
                          </div>
                        </mat-panel-title>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" (click)="onHistoricalTrackingVisibilityClick(item.value); $event.stopPropagation();"
                                  matTooltip="Mostra/Esconde o Rastro" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onHistoricalTrackingRemoveClick(item.key, item.value); $event.stopPropagation();"
                                  matTooltip="Remove Rastro" class="small-button mr-1">
                                  <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </mat-expansion-panel-header>

                      <div class="historic-track-bar bg-shade-50 p-1" *ngIf="item.value.listed">
                        <div class="py-1 px-2 d-block bg-shade-100 my-2 rounded">
                          <mat-slide-toggle [(ngModel)]="item.value.stateVisible" *ngIf="item.value.operation && item.value.signals.length!=0"
                            (change)="onToggleStateMarkerChange(item.value)"
                            matTooltip="Mostra os marcadores de troca de estado (status de operação, status de ponto e ponto sem acesso)">
                            Status e Acesso
                          </mat-slide-toggle>
                          <mat-slide-toggle [(ngModel)]="item.value.timeVisible" class="ml-2" *ngIf="item.value.signals.length!=0"
                            (change)="onToggleTimeMarkerChange(item.value)"
                            matTooltip="Mostra marcas no rastro a cada 50m para identificar o tempo em que ocorreu a localização.">
                            Tempo
                          </mat-slide-toggle>
                        </div>
                        <div class="my-2" *ngIf="!item.value.operation">
                          <div class="mat-body-1 my-2">Intervalo de Tempo:</div>
                          <mat-form-field [style.width.%]=50 class="pr-1 no-hint-text" appearance="outline" [ngClass]="{'mat-form-field-invalid': item.value.firstDateInvalid}">
                            <mat-label>Data Inicial</mat-label>
                            <input matInput name="firstDate" placeholder="Data Inicial" [(ngModel)]="item.value.firstDate" 
                              (keyup)="checkDate($event, item.value, 'firstDateInvalid')" (keypress)="onDateKeypressEvent($event)">
                          </mat-form-field>
                          <mat-form-field [style.width.%]=50 appearance="outline" class="no-hint-text" [ngClass]="{'mat-form-field-invalid': item.value.firstTimeInvalid}">
                            <mat-label>Hora Inicial</mat-label>
                            <input matInput name="firstTime" placeholder="Hora Inicial" [(ngModel)]="item.value.firstTime" 
                              (keyup)="checkTime($event, item.value, 'firstTimeInvalid')" (keypress)="onTimeKeypressEvent($event)">
                          </mat-form-field>
                          <mat-form-field [style.width.%]=50 class="pr-1 no-hint-text" appearance="outline" [ngClass]="{'mat-form-field-invalid': item.value.lastDateInvalid}">
                            <mat-label>Data Final</mat-label>
                            <input matInput name="lastDate"  placeholder="Data Final" [(ngModel)]="item.value.lastDate" 
                              (keyup)="checkDate($event, item.value, 'lastDateInvalid')" (keypress)="onDateKeypressEvent($event)">
                          </mat-form-field>
                          <mat-form-field [style.width.%]=50 appearance="outline" class="no-hint-text" [ngClass]="{'mat-form-field-invalid': item.value.lastTimeInvalid}">
                            <mat-label>Hora Final</mat-label>
                            <input matInput name="lastTime" placeholder="Hora Final" [(ngModel)]="item.value.lastTime" 
                              (keyup)="checkTime($event, item.value, 'lastTimeInvalid')" (keypress)="onTimeKeypressEvent($event)">
                          </mat-form-field>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" *ngIf="!item.value.operation">
                          <button mat-raised-button (click)="onHistoricalTrackingLoadClick(item.value)" fxFlex
                                  matTooltip="Carrega sinais no intervalo de tempo" [disabled]="item.value.firstDateInvalid || item.value.firstTimeInvalid || item.value.lastDateInvalid || item.value.lastTimeInvalid">
                            Carregar
                          </button>
                        </div>
                        <div class="my-2" *ngIf="item.value.signals.length!=0">
                          <div class="mat-body-1 my-2">Filtro:</div>
                          <mat-form-field [style.width.%]=50 class="pr-1 no-hint-text" appearance="outline" [ngClass]="{'mat-form-field-invalid': item.value.startDateInvalid}">                            
                            <mat-label>Data Inicial</mat-label>
                            <input matInput name="startDate" placeholder="Data Inicial" [(ngModel)]="item.value.startDate"
                              (keyup)="checkDate($event, item.value, 'startDateInvalid')" (keypress)="onDateKeypressEvent($event)">
                          </mat-form-field>
                          <mat-form-field [style.width.%]=50 appearance="outline" class="no-hint-text" [ngClass]="{'mat-form-field-invalid': item.value.startTimeInvalid}">
                            <mat-label>Hora Inicial</mat-label>
                            <input matInput name="startTime" placeholder="Hora Inicial" [(ngModel)]="item.value.startTime"
                              (keyup)="checkTime($event, item.value, 'startTimeInvalid')" (keypress)="onTimeKeypressEvent($event)">
                          </mat-form-field>
                          <mat-form-field [style.width.%]=50 class="pr-1 no-hint-text" appearance="outline" [ngClass]="{'mat-form-field-invalid': item.value.finishDateInvalid}">                            
                            <mat-label>Data Final</mat-label>
                            <input matInput name="finishDate"  placeholder="Data Final" [(ngModel)]="item.value.finishDate"
                              (keyup)="checkDate($event, item.value, 'finishDateInvalid')" (keypress)="onDateKeypressEvent($event)">
                          </mat-form-field>
                          <mat-form-field [style.width.%]=50 appearance="outline" class="no-hint-text" [ngClass]="{'mat-form-field-invalid': item.value.finishTimeInvalid}">
                              <mat-label>Hora Final</mat-label>
                              <input matInput name="finishTime" placeholder="Hora Final" [(ngModel)]="item.value.finishTime"
                                (keyup)="checkTime($event, item.value, 'finishTimeInvalid')" (keypress)="onTimeKeypressEvent($event)">
                            </mat-form-field>
                        </div>
                        <div class="px-2 d-block" *ngIf="item.value.signals.length!=0">
                          <mat-slider matTooltip="Inicial" [style.width.%]=50 style="padding:0" (input)="onStartTimeSliderChange($event, item.value)"
                            [(ngModel)]="item.value.startTimestamp" [min]="item.value.firstSignalTimestamp" [max]="item.value.finishTimestamp"></mat-slider>
                          <mat-slider matTooltip="Final" [style.width.%]=50 style="padding:0" (input)="onFinishTimeSliderChange($event, item.value)"
                            [(ngModel)]="item.value.finishTimestamp" [min]="item.value.startTimestamp" [max]="item.value.lastSignalTimestamp"></mat-slider>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" *ngIf="item.value.signals.length!=0">
                          <button mat-raised-button (click)="onHistoricalTrackingResetFilterClick(item.value)" fxFlex
                                  matTooltip="Remove filtro de intervalo de tempo">
                            Limpar
                          </button>
                          <button mat-raised-button (click)="onHistoricalTrackingApplyFilterClick(item.value)" fxFlex
                                  matTooltip="Aplica filtro de intervalo de tempo"
                                  [disabled]="!item.value.signals || item.value.signals.length == 0 || item.value.startDateInvalid || item.value.startTimeInvalid || item.value.finishDateInvalid || item.value.finishTimeInvalid">
                            Aplicar
                          </button>
                          <button mat-raised-button (click)="onHistoricalTrackingFilterDataClick(item.value)" fxFlex
                                  matTooltip="Mostra tabela com os dados dos sinais">
                            Dados
                          </button>
                        </div>
                        <div class="px-2 d-block" *ngIf="item.value.signals.length==0">
                          <mat-label>Sem sinais</mat-label>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Alertas-->
              <mat-expansion-panel [disabled]="alertObjectsMap.size==0" *ngIf="!searchValue || alertsListedCount > 0" [expanded]="alertsExpanded" (opened)="alertsExpanded=true" (closed)="alertsExpanded=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">priority_high</mat-icon>
                    <span class="mat-body-2 ml-1">Alertas</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && alertObjectsMap.size!=0">({{alertsListedCount}}/{{alertObjectsMap.size}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && alertObjectsMap.size!=0">({{alertObjectsMap.size}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="alertObjectsMap.size!=0">
                      <button mat-icon-button color="accent" (click)="onAlertsVisibilityClick(); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todos os Alertas">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="alertsVisible">visibility</span>
                          <span *ngIf="!alertsVisible">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onAlertsRemoveClick(); $event.stopPropagation();"
                              matTooltip="Remove todos os alertas">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="alertObjectsMap.size!=0">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of alertObjectsMap | keyvalue:alertListOrder" style="cursor: pointer;">
                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="item.value.listed">
                        <div class="ml-2 mr-2" (click)="onAlertClick(item.value)">
                          <span *ngIf="item.value.alert.identifier"> {{ item.value.alert.identifier }} - {{item.value.alert.sourceType == 'MOBILE_APP' ? 'APP': 'SIMF'}}<span *ngIf="item.value.alert.patrolTeam"> - </span>{{item.value.alert.patrolTeam?.name}}<span *ngIf="item.value.alert.sourceType == 'SIMF'"> - OPS </span> {{item.value.alert.simf?.cpsNumber}}</span>
                          <span *ngIf="!item.value.alert.identifier"> {{ item.value.alert.timestamp | date:'dd/MM/yyyy HH:mm' }} <span *ngIf="item.value.alert.patrolTeam"> - </span>{{item.value.alert.patrolTeam?.name}}</span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" (click)="onAlertVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde o Alerta" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onAlertRemoveClick(item.value)"
                                  matTooltip="Remove o Alerta" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>

              <!--Panel de Pontos-->
              <mat-expansion-panel [disabled]="pointObjectsMap.size==0" *ngIf="!searchValue || pointsListedCount > 0" [expanded]="pointsExpanded" (opened)="pointsExpanded=true" (closed)="pointsExpanded=false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined">location_on</mat-icon>
                    <span class="mat-body-2 ml-1">Pontos</span>
                    <mat-panel-description>
                      <div class="ml-auto mr-1" *ngIf="searchValue && pointObjectsMap.size!=0">({{pointsListedCount}}/{{pointObjectsMap.size}})</div>
                      <div class="ml-auto mr-1" *ngIf="!searchValue && pointObjectsMap.size!=0">({{pointObjectsMap.size}})</div>
                    </mat-panel-description>
                    <div class="ml-auto" *ngIf="pointObjectsMap.size!=0">
                      <button mat-icon-button color="accent" (click)="onPointsVisibilityClick(); $event.stopPropagation();"
                              matTooltip="Mostra/Esconde todos os Pontos">
                        <mat-icon class="material-icons-outlined icon-sm">
                          <span *ngIf="pointsVisible">visibility</span>
                          <span *ngIf="!pointsVisible">visibility_off</span>
                        </mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="onPointsRemoveClick(); $event.stopPropagation();"
                              matTooltip="Remove todos os pontos">
                        <mat-icon class="material-icons-outlined icon-sm">clear</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="historic-track-bar bg-shade-50 p-1" *ngIf="pointObjectsMap.size!=0">
                  <mat-list role="list" class="pt-0">
                    <mat-list-item role="listitem" *ngFor="let item of pointObjectsMap | keyvalue:pointListOrder" style="cursor: pointer;">

                      <div class="d-flex w-100 mb-2 align-items-center" *ngIf="item.value.listed">
                        <div class="ml-2 mr-2" (click)="onPointClick(item.value)">
                          <span> {{ item.value.inspectionPoint.identifier }} - {{item.value.inspectionPoint.band}} {{kmIntToString(item.value.inspectionPoint.km)}} - {{item.value.inspectionPoint.vulnerabilityView}}</span>
                        </div>
                        <div class="d-flex ml-auto">
                          <button mat-icon-button color="accent" (click)="onPointVisibilityClick(item.value)"
                                  matTooltip="Mostra/Esconde o Ponto" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">
                              <span *ngIf="item.value.visible">visibility</span>
                              <span *ngIf="!item.value.visible">visibility_off</span>
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" (click)="onPointRemoveClick(item.value)"
                                  matTooltip="Remove o Ponto" class="small-button">
                            <mat-icon class="material-icons-outlined icon-sm2">clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>

              <mat-divider></mat-divider>
              
            </mat-accordion>

          </div>

        </div>

      </mat-sidenav>

      <mat-sidenav-content  #sidenavcontent>
        <app-base-map [filterMap]="layersFilter" [mapStyle]="mapStyle" (mapReady)="mapReady($event)" (layerReady)="layerReady($event)"
          (mapEditUpdate)="mapEditUpdate($event)" (eventClicked)="eventClicked($event)" (verificationClicked)="verificationClicked($event)">
        </app-base-map>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>

  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
