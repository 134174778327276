/* tslint:disable:import-spacing */
import { Component, OnDestroy, ChangeDetectorRef, Inject, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {FilterPipe} from 'ngx-filter-pipe';
import {PatrolModel} from '../../../model/patrol.model';
import {PatrolService} from '../../../service/model/patrol.service';
import {environment} from '../../../../environments/environment';
import {OperationStatus, OperationType, Permission, UserType} from 'src/app/model/enums.enum';
import * as moment from 'moment';
import {RouteGeographicalService} from '../../../service/model/route-geographical-service';
import { StorageService } from 'src/app/service/storage-service';
import { ToastrService } from 'ngx-toastr';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import * as GoldenLayout from 'golden-layout';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { PointService } from 'src/app/service/model/point.service';
import { OperationEditComponent } from '../../operation-edit.component';
import { PatrolRepetitionEditDialogComponent } from '../patrol-repetition-edit-dialog/patrol-repetition-edit-dialog.component';
import { first } from 'rxjs/operators';
import { PatrolRepetitionModel } from 'src/app/model/patrol.repetition.model';
import DateUtils from 'src/app/service/util/date-utils';
import { SAVE_DATA_PREFIX, LatLongPattern, LatLongMask } from '../../../common/constants';
import { EntityModel } from '../../../model/entity.model';
import { OperationModel } from 'src/app/model/operation.model';
import { saveAs } from 'file-saver/dist/FileSaver';
import { ResultOccurrenceDescription } from '../../../model/enums.enum';
import FieldUtils from '../../../service/util/field-utils';
import { ResultsModel } from '../../../model/results.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';

@ProfileClassToConsole()
@Component({
  selector:    'app-patrol-edit',
  templateUrl: './patrol-edit.component.html',
  styleUrls:   [ '../../../app.component.scss', './patrol-edit.component.scss'],
})
export class PatrolEditComponent extends OperationEditComponent implements OnInit, OnDestroy {

  referencePatrolId;
  savePredictedOption;

  resultOccurrenceDescription = ResultOccurrenceDescription;
  latLongMask = LatLongMask;
  latLongPattern = LatLongPattern;

  disabledPlannerField : boolean = true;

  isHiddenButtonCreateEditPatrol: boolean;
  isHiddenButtonDeletePatrol: boolean;
  isHiddenButtonEditForUser: boolean;
  
  constructor(logger:                       NGXLogger,
              public patrolService:         PatrolService,
              public entityCacheService:    EntityCacheService,
              public singleDataCacheService:SingleDataCacheService,
              protected filter:             FilterPipe,
              protected routeGeographicalObjectService: RouteGeographicalService,
              dialog:                       MatDialog,
              protected changeDetector:     ChangeDetectorRef,
              toastr:                       ToastrService,
              protected storageService:     StorageService,
              authorizationService:         AuthorizationService,
              protected pointService:       PointService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, OperationType.PATROL, patrolService, dialog,
          environment.PATROL_MODEL_LABEL, environment.PATROL_TITLE_LABEL, storageService,
          'patrols-edit', environment.PATROL_GROUP_LABEL, entityCacheService,
          routeGeographicalObjectService, changeDetector,
          toastr, authorizationService, pointService, goldenLayout, container);
  }

  ngOnInit() {
    super.ngOnInit();
    this.logger.debug('PatrolEditComponent.ngOnInit()');

    this.disabledPlannerField = !this.entityCacheService.isAdminProfile(this.loggedUser.profileId);
  }

  createData(options?) {
    this.logger.debug('PatrolEditComponent.createData()');

    this.model = new PatrolModel();
    this.view = {};

    this.createOperationData();

    let startDate = moment();
    this.view['startDate'] = startDate.format('DD/MM/yyyy');
    this.view['startTime'] = startDate.format('HH:mm');

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  clearCopyData(){
    super.clearCopyData();

    const startDate = (new Date()).getTime();
    this.model['startDate'] = startDate;

    this.model['result'] = null;
  }

  mapViewToModel() {
    super.mapViewToModel();

    this.logger.debug('PatrolEditComponent.mapViewToModel()');

    const startDate = DateUtils.stringDateTimeToTimestamp(this.view['startDate'], this.view['startTime'], true);
    this.model['startDate'] = startDate;
  }

  initializeFields(){
    this.view['result'] = new ResultsModel();
    let date = moment();
    this.view['startDate'] = date.format('DD/MM/yyyy');
    super.initializeFields();
  }

  mapModelToView(){
    this.logger.debug('PatrolEditComponent.mapModelToView()');

    super.mapModelToView();

    let startDate = moment(this.model['startDate']);
    this.view['startDate'] = startDate.format('DD/MM/yyyy');
    this.view['startTime'] = startDate.format('HH:mm');

    if (!this.model['result']) {
      this.view['result'] = new ResultsModel();
    }    
  }

  isRequiredFieldFilled(): boolean {
    if (!this.view['author'] || // planejador
        !this.view['name'] ||
        !this.view['startDate'] ||
        !this.view['startTime'] ||
        !this.view['placement'] ||
        !this.view['company'] ||
        !this.view['serviceType'] ||
        !this.view['patrolTeam'] || !this.view['patrolTeam'].name ||
        !(DateUtils.stringDateTimeToTimestamp(this.view['startDate'], this.view['startTime'], true)) ||
        !this.hasNonBasePoint() ||
        !this.isInspectionsFilled()) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['author']) names.push('Planejador');
    if (!this.view['name']) names.push('Nome da Ronda');
    if (!this.view['startDate']) names.push('Data');
    if (!this.view['startTime']) names.push('Hora');
    if (!this.view['placement']) names.push('Lotação');
    if (!this.view['company']) names.push('Empresa');
    if (!this.view['serviceType']) names.push('Tipo de Serviço');
    if (!this.view['patrolTeam'] || !this.view['patrolTeam'].name) names.push('Equipe');
    if (!this.hasNonBasePoint()) names.push('1 ponto que não seja base');
    if (!this.isInspectionsFilled()) names.push('Pontos sem Faixa ou KM');
    return names;
  }

  afterLoadData(options){
    this.model['type'] = OperationType.PATROL;
    this.updateAttachmentData(); // carrega os arquivos anexo do patrol

    if(options?.predicted){
      this.referencePatrolId = options.referencePatrolId;
      this.clearCopyData();
      this.model['startDate'] = options.startDate;
      this.model['identifier'] = "[PREVISTA]";
    }
  }

  savePredictedPatrol() {
    const dialogRef = this.dialog.open(PatrolRepetitionEditDialogComponent, {
      panelClass: 'sipd-modal',
      data: true
    });

    dialogRef.afterClosed().pipe(first()).subscribe( (result) => {
      if(result == false || result == undefined) {
        return;
      }

      this.savePredictedOption = result; // Nesse caso, precisamos usar esse valor depois que a ronda foi salva

      this.isSaving = true;
      this.backupModel = this.copyModel(this.model);
      this.mapViewToModel();

      this.saveCreatedModel();
    });
  }

  saveReferencePatrol(saveClickEvent) {
    const dialogRef = this.dialog.open(PatrolRepetitionEditDialogComponent, {
      panelClass: 'sipd-modal',
      data: false
    });

    dialogRef.afterClosed().pipe(first()).subscribe( (result) => {
      if(result == false || result == undefined) {
        return;
      }

      if(result === 'justThisPatrol'){
        // Remove a ronda antiga, a repetição ainda tem acesso a ela
        this.patrolService.delete(this.model).pipe(first()).subscribe(() => {});
        let deletedPatrol = this.copyModel(this.model);
        setTimeout(() => {
          this.glEmitEvent(SAVE_DATA_PREFIX + "patrols-edit", {entity: deletedPatrol}); // Isso fará com que a lista seja recarregada
        }, 2500);

        // Salva uma cópia com as modificações, e sem ser uma ronda de referência
        this.id = null;
        this.view['id'] = null;
        this.view['identifier'] = null;
        this.view['patrolRepetitionId'] = null;
        super.onSaveClick(saveClickEvent);
      }
      else if(result === 'fromThisPatrol'){
        super.onSaveClick(saveClickEvent);
      }
    });
  }

  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário
    this.replaceModelViewData(data, 'patrolRepetitionId');
    super.replaceRunTimeData(data);
  }

  onSaveClick(saveClickEvent) {
    this.logger.debug('PatrolEditComponent.onSaveClick()');

    // Necessary to avoid the form to be submitted before attaching the KML files
    saveClickEvent?.preventDefault();

    if(this.referencePatrolId){
      this.savePredictedPatrol();
      return;
    }

    let patrol: PatrolModel = this.model as PatrolModel;
    if(patrol.patrolRepetitionId){
      this.saveReferencePatrol(saveClickEvent);
      return;
    }

    super.onSaveClick(saveClickEvent);
  }

  onExportClick() {
    this.loadingListService.loadingOn();
    this.patrolService.exportPatrol(this.model as PatrolModel).pipe(first()).subscribe(response => {
      this.loadingListService.loadingOff();

      let filename = `${(this.model as PatrolModel).identifier}.zip`;
      filename = filename.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      saveAs(response, filename);
    },
    error =>{
      this.loadingListService.loadingOff();
      this.logger.error(error);
    });
  }

  isPredicted(entity: EntityModel){
    let operation: OperationModel = entity as OperationModel;
    if(operation?.identifier?.endsWith('PREVISTA')) {
      return true;
    }
    else{
      return false;
    }
  }

  hasArchivalRequirements(patrol: PatrolModel){
    if (this.isPredicted(patrol)) {
      return false;
    }

    if(patrol.status != OperationStatus.FINISHED){ // Verifica se o status é Concluído
      return false;
    }

    if(this.authorizationService.isControl()){ // Se for perfil ANALYSIS_CCPD, verifica se as rondas foram criados por ele
      if(!patrol.author || patrol.author.id !== this.loggedUser.id.toString()){
        return false;
      }
    }

    return true;
  }

  // Chamada depois que a ronda foi criada
  operationCreated(entity){
    // Aqui process apenas as rondas previstas que são editadas e salvas
    if(!this.referencePatrolId){
      return;
    }

    this.patrolService.getPatrolRepetitionByReferencePatrolId(this.referencePatrolId).pipe(first()).subscribe((result:PatrolRepetitionModel) => {
      this.logger.debug("** Sucesso: Obter regra de repetição da ronda ");

      if(this.savePredictedOption === 'justThisPatrol'){
        this.editJustThisPatrol(result);
      }
      else if(this.savePredictedOption === 'fromThisPatrol'){
        this.editFromThisPatrol(result, entity);
      }
      this.savePredictedOption = null;

    },err => {
      this.toastr.error("Falha ao buscar regra de repetição para a ronda ", "Erro");
      this.logger.error(JSON.stringify(err));
    });
  }

  editJustThisPatrol(patrolRepetition:PatrolRepetitionModel){
    // Apenas exclui a ronda prevista da repetição, pois ela já foi criada
    let excludedDate: number = DateUtils.getStartOfDayUTC(this.model['startDate']);
    if(patrolRepetition.excludedRepetitions == null) patrolRepetition.excludedRepetitions = [];
    patrolRepetition.excludedRepetitions.push(excludedDate.toString());

    this.patrolService.updatePatrolRepetition(patrolRepetition).pipe(first()).subscribe((result:PatrolRepetitionModel) => {
      this.logger.debug("** Sucesso patrolRepetition ID = " + patrolRepetition.id + " StartDate = " + moment(patrolRepetition.startDate).format('DD/MM/YYYY HH:mm'));
      this.toastr.success("Atualização de repetição de rondas realizada com sucesso","Sucesso");
      this.glEmitEvent("updatePatrolRepetitionList", null);
    }
    ,err => {
      this.toastr.error("** Falha ao editar regra de repetição para a ronda ", "Erro");
      this.logger.error(JSON.stringify(err));
    });
  }

  editFromThisPatrol(oldPatrolRepetition:PatrolRepetitionModel, patrol: PatrolModel){
    // Usa a ronda criada como ronda de referência

    //copia da repetição original
    let newPatrolRepetition = this.copyModel(oldPatrolRepetition) as PatrolRepetitionModel;

    newPatrolRepetition.referencePatrolId = patrol.id;
    newPatrolRepetition.referencePatrolIdentifier = patrol.identifier;
    newPatrolRepetition.id = null;

    //set dataStart e hora da nova regra baseada na nova ronda de referência
    newPatrolRepetition.startDate = moment(patrol.startDate).startOf('day').valueOf(); // Horário local
    newPatrolRepetition.startTime = moment(patrol.startDate).format("HH:mm");

    // Remove a ronda criada da repetição
    let excludedDate: number = DateUtils.getStartOfDayUTC(patrol.startDate);

    newPatrolRepetition.excludedRepetitions = [];
    newPatrolRepetition.excludedRepetitions.push(excludedDate.toString());

    for(const excluded of oldPatrolRepetition.excludedRepetitions) {
      if (moment(parseInt(excluded)).isAfter(excludedDate)) {
        newPatrolRepetition.excludedRepetitions.push(excluded);
      }
    }

    // Atualiza ronda antiga para terminar 1 dia antes da data da nova ronda
    let endDate = moment(patrol.startDate).add(-1,'days').startOf('day'); // Horário local

    // Se possui um número de repetição, esse número precisa ser atualizado
    if(newPatrolRepetition.afterNumberRepetitionsToFinish && newPatrolRepetition.eachPeriod){
      let oldStartDate = moment(oldPatrolRepetition.startDate).startOf('day'); // Horário local
      let unitPeriod = newPatrolRepetition.unitPeriodSelected as moment.unitOfTime.DurationConstructor;
      let eachQuantity = newPatrolRepetition.theEachQuantity;

      // Decrementa as repetições que já aconteceram
      while(endDate.isAfter(oldStartDate)){
        newPatrolRepetition.afterRepetitionQuantity -= 1;
        oldStartDate = oldStartDate.add(eachQuantity, unitPeriod);
      }
    }

    oldPatrolRepetition.endDate = endDate.valueOf();
    oldPatrolRepetition.finishDate = true;
    oldPatrolRepetition.neverFinish = false;
    oldPatrolRepetition.afterNumberRepetitionsToFinish = false;
    oldPatrolRepetition.afterRepetitionQuantity = 0;

    let updatePatrolRepetitionPromise = this.patrolService.updatePatrolRepetition(oldPatrolRepetition).toPromise();
    let createPatrolRepetitionPromise = this.patrolService.createPatrolRepetition(newPatrolRepetition).toPromise();

    Promise.all([updatePatrolRepetitionPromise, createPatrolRepetitionPromise])
      .then(()=>{
        this.logger.debug("** Sucesso oldPatrolRepetition ID = " + oldPatrolRepetition.id + " StartDate = " + moment(oldPatrolRepetition.startDate).format('DD/MM/YYYY HH:mm'));
        this.logger.debug("** Sucesso newPatrolRepetition ID = " + newPatrolRepetition.id + " StartDate = " + moment(newPatrolRepetition.startDate).format('DD/MM/YYYY HH:mm'));
        this.toastr.success("Atualização de repetição de rondas realizada com sucesso","Sucesso");
        this.glEmitEvent("updatePatrolRepetitionList", null);
      },
      error => {
        this.toastr.error("Falha ao editar repetição", "Erro");
        this.logger.error(JSON.stringify(error));
      });
  }

  canHasHistoricalTracking(){
    if(this.isPredicted(this.model)) {
      return false;
    }
    return super.canHasHistoricalTracking();
  }

  protected getRecord(id: string, extraParams?: Map<string, string>, options?: any) {
    if(options?.predicted){
      return this.patrolService.getReferencePatrolById(id); // Se a Ronda editada for prevista a Ronda de Referência pode ter sido removida,
                                                            // daí um end point específico para Rondas Previstas
      }
    else {
      return this.entityService.getRecord(id, this.getExtraParams());
    }
  }

  loadFormOptionsData() {
    super.loadFormOptionsData();

    this.logger.debug('PatrolEditComponent.loadFormOptionsData()');

    const metadatas: string[] = [
      "InspectionType",
      "Band",
      "Duct",
      "PipeStretch",
      "Valve"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  onPasteLatLong(event: ClipboardEvent, name: string){
    let pastedValue = FieldUtils.pasteLatLong(event, this.view[name]);
    if (pastedValue != null) {
       this.view[name] = pastedValue;
       return true;
    }
    return false;
  }

  onPasteResultLatLong(event: ClipboardEvent, name: string){
    let pastedValue = FieldUtils.pasteLatLong(event, this.view['result'][name]);
    if (pastedValue != null) {
       this.view['result'][name] = pastedValue;
       return true;
    }
    return false;
  }

  isPatrolDeletionPermitted() {
    return this.authorizationService.isPatrolDeletionAllowed(<PatrolModel>this.model);
  }

  checkPermissions(){
    this.isHiddenButtonCreateEditPatrol = !this.authorizationService.userHasPermission(Permission.CREATE_EDIT_PATROL);
    this.isHiddenButtonDeletePatrol = !this.authorizationService.userHasPermission(Permission.DELETE_PATROL);
    this.isHiddenButtonEditForUser = !this.authorizationService.userHasPermission(Permission.CREATE_EDIT_PATROL);
  }
}
